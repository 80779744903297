import gql from 'graphql-tag';

export const GET_PROTOCOLS = gql`
  query ($first: Int, $hospitalUuid: ID!, $cursor: String, $orderBy: ProtocolOrder) {
    protocols(first: $first, hospitalUuid: $hospitalUuid, after: $cursor, orderBy: $orderBy) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ... on Protocol {
            uuid
            name
            minDaysToVisit
            state
            questions {
              uuid
              type
              question
              sequence
              helpText
              helpPictures {
                uuid
                picture
                createdAt
                updatedAt
              }
              options {
                uuid
                optionText
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

export const GET_PROTOCOL = gql`
  query ($uuid: ID!) {
    protocol(uuid: $uuid) {
      uuid
      name
      minDaysToVisit
      state
      questions {
        uuid
        type
        question
        sequence
        helpText
        helpPictures {
          uuid
          picture
          createdAt
          updatedAt
        }
        options {
          uuid
          optionText
          score
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_PROTOCOL = gql`
  mutation ($input: CreateProtocolInput!) {
    createProtocol(input: $input) {
      uuid
      name
      minDaysToVisit
      state
      createdBy
      hospitalUuid
      questions {
        uuid
        type
        question
        sequence
        helpText
        helpPictures {
          uuid
          picture
          createdAt
          updatedAt
        }
        options {
          uuid
          optionText
          score
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
