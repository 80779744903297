import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ContainerUIBox } from './ContainerUI';

const useStyles = makeStyles({
  articleContainer: {
    '& > div.MuiContainer-root.article': {
      position: 'absolute',
      top: 0,
      paddingTop: 85,
      paddingBottom: 25,
      '@media (max-width:1099px)': {
        paddingBottom: 20,
      },
      '@media (max-width:600px)': {
        paddingTop: 95,
      },
    },
    '& > nav': {
      position: 'absolute',
      top: 85,
      right: 0,
      width: '100%',
      height: 'calc(100vh - 158px)',
      '@media (max-width:1099px)': {
        top: 85,
        height: 'calc(100vh - 146px)',
      },
      '@media (max-width:600px)': {
        top: 97,
        height: 'calc(100vh - 146px)',
      },
    },
  },
});

export const ArticleContainerUI = ({ children }) => {
  const styles = useStyles();
  return (
    <ContainerUIBox className={styles.articleContainer}>
      {children}
    </ContainerUIBox>
  );
};

export default ArticleContainerUI;
