import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const ContainerUIBox = withStyles({
  root: {
    position: 'relative',
    '& > div[class^="makeStyles-navbar"]': {
      position: 'absolute',
      top: 0,
    },
  },
})(Box);

const useStyles = makeStyles({
  container: {
    '& > div.MuiContainer-root.article': {
      position: 'absolute',
      top: 0,
      paddingTop: 65,
      paddingBottom: 45,
      '@media (max-width:600px)': {
        paddingTop: 75,
      },
    },
  },
  responsive: {
    overflow: 'hidden',
    height: '100%',
    '@media (max-height:600px)': {
      height: 'calc(100vh - 129px)',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
});

export const ContainerUI = ({ children }) => {
  const styles = useStyles();
  return (
    <Box className={styles.container}>
      {children}
    </Box>
  );
};

export const ResponsiveListWrapperUI = ({ children }) => {
  const styles = useStyles();
  return (
    <Box className={styles.responsive}>
      {children}
    </Box>
  );
};

export default ContainerUI;
