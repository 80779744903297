import React from 'react';

import Layout from '../../components/MenuLayout';
import { DashboardView } from '../../components/Dashboard/DashboardView';
import { DashboardView as PatientDashboardView } from '../../components/Telemedicine/DashboardView';
import { useApplicationInterface } from '../../utils/ApplicationInterfaceUtils';

const { patientApp } = useApplicationInterface();

export default () => (
  <Layout>
    {patientApp ? <PatientDashboardView /> : <DashboardView />}
  </Layout>
);
