import React from 'react';

import { UserCommentWrapper, CommentHeader, CommentBody } from '../styled/CaseCommentsStyles';
import { CommentHeaderContent } from '../../Common/Comment/CommentHeaderContent';
import { InnerSpanHTML } from '../../Common/InnerHTML';

export const UserCommentItem = ({ comment, fromNow }) => (
  <UserCommentWrapper>
    <CommentHeader>
      <CommentHeaderContent comment={comment} fromNow={fromNow} />
    </CommentHeader>
    <CommentBody>
      <InnerSpanHTML content={comment.body} />
    </CommentBody>
  </UserCommentWrapper>
);

export default UserCommentItem;
