import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import Layout from '../../components/MenuLayout';
import CaseDetails from '../../components/Case/CaseDetails';
import CasePatientDetails from '../../components/Case/CasePatientDetails';
import Loading from '../../components/Common/Loading';
import { GET_MEDICAL_CASE_FOR_EDITING } from '../../graphql/queries';
import { useApplicationInterface } from '../../utils/ApplicationInterfaceUtils';
import { networkErrorParse } from '../../utils/ErrorGraphQLUtils';

export default ({ caseId }) => {
  const [errorMessage, setErrorMessage] = useState(false);
  const { loading, error, data, refetch } = useQuery(
    GET_MEDICAL_CASE_FOR_EDITING,
    {
      variables: { uuid: caseId },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  if (!errorMessage && error) setErrorMessage(networkErrorParse(error));

  const { patientApp } = useApplicationInterface();
  const CaseComponent = patientApp ? CasePatientDetails : CaseDetails;
  const medicalCase = data && data.medicalCase;
  const renderCase = medicalCase || errorMessage;

  return (
    <Layout>
      {loading && !renderCase && <Loading />}
      {renderCase && <CaseComponent medicalCase={medicalCase} refetch={refetch} errorMessage={errorMessage} />}
    </Layout>
  );
};
