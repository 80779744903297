import React from 'react';
import { useTranslation } from 'react-i18next';

import { InnerHTML } from '../../Common/InnerHTML';

const PatientEmailWarning = ({ patient }) => {
  const { t } = useTranslation();

  const patientName = `${patient.name} ${patient.surname} `;
  const patientEmailWarning1 = t('virtual.visit.patient.with.no.email.warning.1', { patientName });
  const patientEmailWarning2 = t('virtual.visit.patient.with.no.email.warning.2');

  return (
    <>
      <InnerHTML content={patientEmailWarning1} />
      <InnerHTML content={patientEmailWarning2} />
    </>
  );
};

export const InvitePatientWarning = ({ patient }) => (!patient ? null : <PatientEmailWarning patient={patient} />);
export default InvitePatientWarning;
