import styled from 'styled-components';

export const PageContent = styled.div`
  height: 100%;
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth};`}
  padding: ${(props) => props.theme.mainPagePadding};
  box-sizing: border-box;
  ${(props) => (props.overflow ? 'overflow-y: auto;' : 'overflow: hidden;')}
  @media (max-width: 959px) {padding: 0.5em;}
  @media (max-width: 600px) {padding: 0.5em 0;}
`;

export const PageListContent = styled.div`
  height: 100%;
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth};`}
  padding: ${(props) => props.theme.mainPagePadding};
  box-sizing: border-box;
  ${(props) => (props.overflow ? 'overflow-y: auto;' : 'overflow: hidden;')}
  @media (max-width: 959px) {padding: 0 0.5em;}
  @media (max-width: 600px) {padding: 0;}
`;
