export const imageExtensions = ['jpg', 'gif', 'png', 'jpeg', 'svg'];

export const getUrlExtension = (urlString) => {
  try {
    const url = new URL(urlString);
    return url.pathname.substr(url.pathname.lastIndexOf('.') + 1).toLowerCase();
  } catch (error) {
    return null;
  }
};

export const getFilenameExtension = (filename) => {
  try {
    return filename.substr(filename.lastIndexOf('.') + 1).toLowerCase();
  } catch (error) {
    return null;
  }
};

export const downloadUri = (uri, filename) => {
  fetch(uri, { method: 'GET' })
    .then((response) => response.blob())
    .then((blob) => {
      const objectUrl = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');

      link.href = objectUrl;
      link.setAttribute('download', filename);

      document.body.appendChild(link);

      link.click();
      link.parentNode.removeChild(link);
    });
};
