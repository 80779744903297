import { makeStyles } from '@material-ui/core';

const useStyles = () => makeStyles(() => ({
  root: {
    position: 'relative',
  },
  control: {
    position: 'absolute',
    top: -4,
    right: -4,
  },
}))();

export default useStyles;
