import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar, Grid, Popover } from '@material-ui/core';
import { GreyLabel } from '../components/Common/styled/GreyLabel';
import { getFormattedDate } from '../utils/dateTimeUtils';
import userInfoStyles from './styles/UserInfoStyles';

const UserInfo = ({ admin, entity, type }) => {
  const { t } = useTranslation();
  const classes = userInfoStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const initials = admin && (admin.name.charAt(0) + admin.surname.charAt(0)).toUpperCase();

  let manager;
  switch (type) {
    case 'study':
      manager = 'study.uploaded.by';
      break;
    case 'medicalCase':
    default:
      manager = 'case.created.by';
  }

  const profilePhoto = admin && admin.profilePhoto;
  return (
    <Grid item>
      {profilePhoto ? (
        <Avatar
          alt="Patient"
          className={classes.avatar}
          src={profilePhoto}
          aria-describedby={id}
          variant="circular"
          onClick={handleClick}
        />
      ) : (
        <Avatar
          className={classes.initials}
          aria-describedby={id}
          variant="circular"
          onClick={handleClick}
        >
          {initials.toUpperCase()}
        </Avatar>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <div className={classes.popover}>
          <GreyLabel>{`${t(manager)}: `}</GreyLabel>
          {admin && `${admin.name} ${admin.surname}`}
          <br />
          <GreyLabel>{`${t('date')}: `}</GreyLabel>
          {getFormattedDate({ date: entity.createdAt })}
          <br />
          <GreyLabel>{`${t('location')}: `}</GreyLabel>
        ...
        </div>
      </Popover>
    </Grid>
  );
};

export default UserInfo;
