/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, FieldArray, Formik } from 'formik';
import * as Yup from 'yup';

import { Box, Button, Grid, Typography } from '@material-ui/core';
import { AddCircle, Backspace, RemoveCircleOutline } from '@material-ui/icons';

import { Error, Form, Label } from '../Common/styled/Form';
import { Dropzone } from '../Common/Dropzone/Dropzone';
import { protocolQuestionTypeList, protocolStateList } from '../../utils/ProtocolUtils';
import { TextFieldUI } from '../../componentsUI/TextField';
import { TextFieldSelectUI } from '../../componentsUI/TextFieldSelect';
import { GetValueToPath } from '../../utils/functions';
import { AlertUI } from '../../componentsUI/Alert';
import { IconButtonUI } from '../../componentsUI/IconButton';
import { useStyles } from './styled/ProtocolFormMakeStyles';

export const ProtocolFormDialog = ({ protocol, formId, handleSubmit, submitting, serverError = false, reset }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const form = useRef(null);
  const [resetStatus, setResetStatus] = useState(0);

  const newSecondaryOption = {
    optionText: '',
    score: '0',
  };

  const newOption = {
    optionText: ' ',
    score: '0',
  };

  const newQuestion = {
    type: 'TEXT',
    question: '',
    options: [newOption],
    helpText: '',
    helpPictures: [],
  };

  const createOption = (option) => ({
    optionText: option.optionText,
    score: option.score,
  });

  const createQuestion = (question) => ({
    type: question.type,
    question: question.question,
    options: question.options.map((option) => createOption(option)),
    helpText: question.helpText,
    helpPictures: question.helpPictures.length ? [{ duplicateUuid: question.helpPictures[0].uuid }] : [],
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required.field')),
    questions: Yup.array().of(Yup.object().shape({
      question: Yup.string().required(t('required.field')),
      options: Yup.array().of(Yup.object().shape({
        optionText: Yup.string().required(t('required.field')),
        score: Yup.string().required(t('required.field')),
      })),
    })),
    minDaysToVisit: Yup.number().min(1, t('number.too.low')),
  });

  const resetValuesOnChange = (type, index) => {
    if (!form.current) return;

    const firstOptionText = form.current.state.values.questions[index].options[0].optionText;

    if (type === 'OPTIONS' && firstOptionText === ' ') {
      form.current.setFieldValue(`questions[${index}].options[0].optionText`, '', true);
    }

    if (type !== 'PICTURE') {
      form.current.setFieldValue(`questions[${index}].helpPictures`, [], false);
      form.current.setFieldValue(`questions[${index}].helpText`, '', true);
    }

    if (type !== 'OPTIONS') {
      form.current.setFieldValue(`questions[${index}].options`, [], false);
      form.current.setFieldValue(`questions[${index}].options[0]`, newOption, true);
    }
  };

  const initialValues = {
    name: protocol ? `${protocol.name} 2` : '',
    questions: protocol ? protocol.questions.map((question) => createQuestion(question)) : [newQuestion],
    minDaysToVisit: protocol ? protocol.minDaysToVisit : 7,
    state: protocol ? protocol.state : '2',
  };

  const getCurrentFile = (obj, path) => GetValueToPath(obj, path) || '';

  if (reset !== resetStatus && form.current) {
    setResetStatus(reset);
    form.current.resetForm();
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      ref={form}
    >
      {(props) => (
        <Form autoComplete="off" id={formId} onSubmit={props.handleSubmit}>
          <fieldset disabled={submitting} className={classes.fieldset}>
            <Grid container spacing={1} className={classes.definition}>
              <Grid item xs={12}>
                <TextFieldUI name="name" label={t('name')} props={props} classes={classes} />
              </Grid>

              <Grid item xs={12} sm={7} md={8}>
                <TextFieldSelectUI
                  name="state"
                  label={t('protocol.scope')}
                  props={props}
                  options={protocolStateList}
                />
              </Grid>

              <Grid item xs={12} sm={5} md={4}>
                <TextFieldUI
                  type="number"
                  name="minDaysToVisit"
                  label={t('min.days.to.visit')}
                  props={props}
                  classes={classes}
                />
              </Grid>
              <br />

              <Grid item xs={12}>
                <Box className={classes.title}>
                  {`${t('protocol.questions')}...`}
                </Box>
              </Grid>
            </Grid>

            <FieldArray name="questions">
              {(arrayHelpersQuestions) => (
                <Grid container spacing={3} className={classes.questions}>
                  {props.values.questions.map((question, qIndex) => (
                    <Grid item xs={12} key={qIndex.toString()}>
                      <Grid container spacing={1}>
                        <Grid item xs={5} sm={4}>
                          <TextFieldSelectUI
                            variant="outlined"
                            name={`questions[${qIndex}].type`}
                            label={t('type')}
                            props={props}
                            options={protocolQuestionTypeList}
                            onClick={(option) => resetValuesOnChange(option, qIndex)}
                          />
                        </Grid>
                        <Grid item xs={7} sm={8} className={classes.question}>
                          <Typography>{`${t('question')} ${qIndex + 1}`}</Typography>
                          <IconButtonUI
                            title={t('remove.question')}
                            Icon={Backspace}
                            disabled={props.values.questions.length < 2}
                            handleClick={() => arrayHelpersQuestions.remove(qIndex)}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <TextFieldUI
                          errors={{ name: 'error description' }}
                          name={`questions[${qIndex}].question`}
                          label={t('question')}
                          props={props}
                          classes={classes}
                        />
                      </Grid>
                      <Grid item xs={12} className={classes.questionInput} />

                      {question.type === 'OPTIONS'
                      && (
                        <FieldArray
                          name={`questions[${qIndex}].options`}
                        >
                          {(arrayHelpersOptions) => (
                            <Grid item xs={12}>
                              {question.options.map((option, oIndex) => (
                                <Grid container spacing={1} key={oIndex.toString()}>
                                  <Grid item xs={12} sm={7} className={classes.option}>
                                    <TextFieldUI
                                      name={`questions[${qIndex}].options[${oIndex}].optionText`}
                                      label={t('option')}
                                      props={props}
                                      classes={classes}
                                    />
                                    <IconButtonUI
                                      title={t('remove.option')}
                                      Icon={RemoveCircleOutline}
                                      disabled={!(question.options && question.options.length > 2)}
                                      handleClick={() => arrayHelpersOptions.remove(qIndex)}
                                      placement="left"
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={5}>
                                    <TextFieldUI
                                      type="number"
                                      name={`questions[${qIndex}].options[${oIndex}].score`}
                                      label={t('score')}
                                      props={props}
                                      classes={classes}
                                      defaultValue={option.score}
                                    />
                                  </Grid>
                                </Grid>
                              ))}
                              <Button
                                variant="outlined"
                                size="small"
                                className={classes.optionButton}
                                color="primary"
                                onClick={() => arrayHelpersOptions.push(newSecondaryOption)}
                              >
                                <AddCircle />
                                {t('add.option')}
                              </Button>
                            </Grid>
                          )}
                        </FieldArray>
                      )}

                      {question.type === 'PICTURE'
                        && (
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <TextFieldUI
                                name={`questions[${qIndex}].helpText`}
                                label={t('help.text')}
                                props={props}
                                classes={classes}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Label htmlFor={`questions[${qIndex}].helpPictures`}>{t('help.file')}</Label>
                              <Dropzone
                                accept="image/jpg, image/gif, image/png, image/jpeg, video/mp4, video/3gpp, video/quicktime"
                                setFile={(image) => props.setFieldValue(`questions[${qIndex}].helpPictures[0].picture`, image)}
                                clearFile={() => props.setFieldValue(`questions[${qIndex}].helpPictures[0].picture`, null)}
                                currentFileUrl={getCurrentFile(protocol, `protocol.questions[${qIndex}].helpPictures[0].picture`)}
                              />
                              <ErrorMessage name={`questions[${qIndex}].helpPictures`} component={Error} />
                            </Grid>
                          </Grid>
                        </Grid>
                        )}
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      size="small"
                      className={classes.button}
                      color="default"
                      onClick={() => arrayHelpersQuestions.push(newQuestion)}
                    >
                      {t('add.question')}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </FieldArray>

            {serverError && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <AlertUI title="Error" severity="error">
                    {t('server.error')}
                  </AlertUI>
                </Grid>
              </Grid>
            )}
          </fieldset>
        </Form>
      )}
    </Formik>
  );
};
