import React, { useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { navigate } from 'gatsby';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import { Column } from '../Common/styled/Groups';
import { GreyLabel } from '../Common/styled/GreyLabel';
import { ExtraBold } from '../Common/styled/Text';
import {
  GET_HOSPITAL_FILES_TO_LIST,
  GET_MEDICAL_CASE_FOR_EDITING,
  GET_PATIENT_RELATED_DATA,
} from '../../graphql/queries';
import { DELETE_HOSPITAL_FILE, DELETE_PATIENT_FILE, DELETE_MEDICAL_CASE_FILE } from '../../graphql/mutations';
import Loading from '../Common/Loading';
import { KBytesPrettify } from '../../utils/BytesPrettifyUtils';
import { AlertUI } from '../../componentsUI/Alert';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: 15,
    },
    '& H4': {
      margin: 0,
    },
  },
  wrapperTitle: {
    display: 'flex',
    padding: '6px 24px',
    '& .MuiDialogTitle-root': {
      padding: 0,
      marginRight: 'auto',
    },
  },
  wrapperSearch: {
    padding: '0 24px',
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
  },
  closeButton: {
    padding: '6px',
  },
  wrapperStep1: {
    padding: '0 24px',
  },
  content: {
    margin: '8px 24px',
    padding: 0,
    paddingRight: 5,
  },
  search: {
    margin: '10px 0',
  },
}));

export const DeleteFileDialog = ({ open, onClose, file = {}, uuid, type = 'hospitalFile' }) => {
  const classes = useStyles();
  const [serverError, setServerError] = useState(false);
  const [disabledButtons, setDisabledButtons] = useState(false);
  const { t } = useTranslation();
  const { uuid: fileUuid } = file;

  const [deleteMedicalCaseFile, { loading: updatingCaseFile }] = useMutation(DELETE_MEDICAL_CASE_FILE, {
    onCompleted() {
      setDisabledButtons(false);
      onClose(true);
      navigate(`/case/${uuid}`);
    },
    refetchQueries: [{
      query: GET_MEDICAL_CASE_FOR_EDITING,
      variables: { uuid },
    }],
    awaitRefetchQueries: true,
  });

  const [deletePatientFile, { loading: updatingPatientFile }] = useMutation(DELETE_PATIENT_FILE, {
    onCompleted() {
      setDisabledButtons(false);
      onClose(true);
    },
    refetchQueries: [{
      query: GET_PATIENT_RELATED_DATA,
      variables: { uuid },
    }],
    awaitRefetchQueries: true,
  });

  const [deleteHospitalFile, { loading: updatingHospitalFile }] = useMutation(DELETE_HOSPITAL_FILE, {
    onCompleted() {
      setDisabledButtons(false);
      onClose(true);
    },
    refetchQueries: [{
      query: GET_HOSPITAL_FILES_TO_LIST,
      variables: { hospitalUuid: uuid },
    }],
    awaitRefetchQueries: true,
  });

  const closeModal = () => {
    setServerError(null);
    onClose();
  };

  const handleSubmit = async () => {
    setServerError(false);
    setDisabledButtons(true);
    const variables = { uuid: fileUuid };
    try {
      switch (type) {
        case 'medicalCaseFile':
          await deleteMedicalCaseFile({ variables });
          break;
        case 'patientFile':
          await deletePatientFile({ variables });
          break;
        case 'hospitalFile':
        default:
          await deleteHospitalFile({ variables });
      }
    } catch (errors) {
      if (errors.graphQLErrors && errors.graphQLErrors.length) setServerError(errors.graphQLErrors[0].message);
      setDisabledButtons(false);
    }
  };

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeModal}
    >
      <DialogTitle>{t('file.confirm.delete')}</DialogTitle>
      {/* <Header title={t('file.confirm.delete')} onClose={closeModal} /> */}
      <DialogContent>
        <p>{t('file.delete.confirmation.message')}</p>
        <Column>
          <ul>
            <li>
              <GreyLabel>
                {t('filename')}
                :&nbsp;
              </GreyLabel>
              <ExtraBold>{`${file.fileName}`}</ExtraBold>
            </li>
            <li>
              <GreyLabel>
                ID:&nbsp;
              </GreyLabel>
              <ExtraBold>{`${file.uuid}`}</ExtraBold>
            </li>
            <li>
              <GreyLabel>
                {t('size')}
                :&nbsp;
              </GreyLabel>
              <ExtraBold>{`${KBytesPrettify(file.fileSize).string}`}</ExtraBold>
            </li>
          </ul>
        </Column>
        {(updatingCaseFile || updatingPatientFile || updatingHospitalFile) && (
          <div className="my-2">
            <Loading />
          </div>
        )}
        {serverError ? <AlertUI severity="error" title="Error">{serverError}</AlertUI> : null}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className={classes.button}
          color="primary"
          onClick={closeModal}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          disabled={disabledButtons}
          onClick={handleSubmit}
        >
          {t('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
