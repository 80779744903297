import React, { useContext, useState } from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';

import {
  CloudUpload,
  CloudUploadTwoTone,
  Delete,
  Edit,
  Event,
  FolderShared,
  NavigateBefore,
  Share,
} from '@material-ui/icons';
import { Box, Container, Grid, Paper } from '@material-ui/core';

import { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from '../../utils/ObjectUtils';
import { isNullOrEmptyString } from '../../utils/StringUtils';
import { CaseComments } from './comments/CaseComments';
import { DeleteCaseDialog } from './modal/DeleteCaseDialog';
import { VirtualVisitAddModal } from '../VirtualVisits/VirtualVisitAddModal';
import { InvitePatientOptionDialog } from '../Patient/InvitePatientOptionDialog';
import { ScrollableContainer } from '../Common/ScrollableContainer';
import { EditCaseDialog } from './modal/EditCaseDialog';
import { SectionBar } from '../../componentsUI/SectionBar';
import { ShareCaseDialog } from '../Share/modal/ShareCaseDialog';
import { PageContent } from '../Common/styled/PageContent';
import { UploadFileDialog } from '../File/UploadFileDialog';
import Explorations from '../Patient/archives/Explorations';
import LaboratoryResults from '../Patient/archives/LaboratoryResults';
import { MedicalCaseMainInfo } from './MedicalCaseMainInfo';
import { MedicalCaseShareInfo } from './MedicalCaseShareInfo';
import useStyles from './styled/CaseMakeStyles';
import CaseDiagnostics from './archives/CaseDiagnostics';
import CaseInterventions from './archives/CaseInterventions';
import CaseRelated from './archives/CaseRelated';
import CaseMedications from './archives/CaseMedications';
import CaseTimeline from './archives/CaseTimeline';
import CaseAnamnesis from './archives/CaseAnamnesis';
import CaseDicomStudies from './archives/CaseDicomStudies';
import CaseMedicalFiles from './archives/CaseMedicalFiles';
import CaseVirtualVisits from './archives/CaseVirtualVisits';
import InvitePatientWarning from '../Patient/modal/InvitePatientWarning';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import Loading from '../Common/Loading';
import { DeleteFileDialog } from '../File/DeleteFileDialog';
import ArticleContainerUI from '../Common/styled/ArticleContainerUI';
import PatientMainProfileInfo from '../Patient/PatientMainProfileInfo';
import PatientSecondaryInfo from '../Patient/PatientSecondaryInfo';
import { setHospitalEnvironment } from '../../utils/HospitalUtils';
import { AlertUI, AlertWrapperUI } from '../../componentsUI/Alert';

export default ({ medicalCase, refetch, errorMessage }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const context = useContext(ThemeContext);
  const dispatch = useDispatch();
  const hospital = useSelector((state) => state.hospital);

  if (hospital && medicalCase && hospital.uuid !== medicalCase.patient.hospital.uuid) {
    setHospitalEnvironment({ uuid: medicalCase.patient.hospital.uuid, dispatch, context });
  }

  const [showShareModal, setShowShareModal] = useState(false);
  const toggleShareModal = () => setShowShareModal(!showShareModal);

  const [showEditModal, setShowEditModal] = useState(false);
  const toggleEditModal = () => setShowEditModal(!showEditModal);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const toggleUploadFileModal = () => setShowUploadFileModal(!showUploadFileModal);

  const [selectedFile, setSelectedFile] = useState(false);
  const [showDeleteFileModal, setShowDeleteFileModal] = useState(false);

  const [showVirtualVisitModal, setShowVirtualVisitModal] = useState(false);
  const toggleVirtualVisitModal = () => setShowVirtualVisitModal(!showVirtualVisitModal);

  const patient = medicalCase && medicalCase.patient;
  const [hasEmail, setHasEmail] = useState(patient && !isNullOrEmptyString(patient.email));
  const [isInvited, setIsInvited] = useState(patient && !isNullOrEmptyString(patient.userUuid));

  const handleBack = () => {
    navigate('/cases');
  };

  const deleteFileRequest = (index) => {
    setSelectedFile(medicalCase.medicalCaseFiles[index]);
    setShowDeleteFileModal(true);
  };

  const handleCloseDeleteFile = () => {
    setShowDeleteFileModal(false);
  };

  const medicalCaseFiles = medicalCase && medicalCase.medicalCaseFiles;
  const virtualVisits = medicalCase && medicalCase.virtualVisits;
  const virtualVisitOption = hospital && hospital.menuOptionVirtualVisits;
  const virtualVisitButton = virtualVisitOption ? [
    { name: 'divider2', type: 'divider' },
    { name: 'new.virtual.visit', icon: Event, I: 'contribute', data: medicalCase, handleClick: () => toggleVirtualVisitModal() },
  ] : [];

  const uploadFileButton = (hospital && hospital.menuOptionFiles) ? [
    { name: 'upload.file', icon: CloudUploadTwoTone, I: 'contribute', data: medicalCase, handleClick: toggleUploadFileModal },
  ] : [];

  const buttons = [
    { name: 'go.back', icon: NavigateBefore, handleClick: handleBack },
    { name: 'divider2', type: 'divider' },
    { name: 'share.case', icon: Share, I: 'contribute', data: medicalCase, handleClick: () => toggleShareModal() },
    { name: 'edit.case', icon: Edit, I: 'edit', data: medicalCase, handleClick: () => setShowEditModal(true) },
    { name: 'delete.case', icon: Delete, I: 'delete', data: medicalCase, handleClick: () => setShowDeleteModal(true) },
    { name: 'divider2', type: 'divider' },
    ...uploadFileButton,
    { name: 'upload.study', icon: CloudUpload, I: 'contribute', data: medicalCase, handleClick: () => navigate(`/study/upload/${medicalCase.uuid}`) },
    ...virtualVisitButton,
  ];

  const backButton = {
    label: t('back.to.medical.cases'),
    icon: FolderShared,
    onClick: handleBack,
  };

  const getApplicationPath = (d) => {
    if (isEmpty(d)) return null;
    return {
      patient: {
        name: `${d.patient.name} ${d.patient.surname}`,
        link: `/patient/${d.patient.uuid}`,
      },
      medicalCase: {
        name: d.title,
        link: `/case/${d.uuid}`,
      },
    };
  };

  const ArchivesWrapper = ({ children }) => (
    <Grid container spacing={1} className={classes.out}>
      <Grid item xs={12} lg={10} xl={7}>
        <Paper elevation={2}>
          <Box className={classes.box}>
            <Grid container spacing={1}>
              <Grid item className={classes.accordion}>
                {children}
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );

  if (errorMessage) {
    return (
      <AlertWrapperUI>
        <AlertUI severity="error" title="Error">{t(errorMessage)}</AlertUI>
      </AlertWrapperUI>
    );
  }

  if (!medicalCase) return <Loading />;

  return (
    <ArticleContainerUI>
      <Navbar className={classes.subheader}>
        <SectionBar title="case.info" items={buttons} back={backButton} breadcrumbs={getApplicationPath(medicalCase)} />

        <ShareCaseDialog
          open={showShareModal}
          onClose={toggleShareModal}
          medicalCase={medicalCase}
          refetch={refetch}
        />
        <EditCaseDialog
          open={showEditModal}
          onClose={toggleEditModal}
          caseId={medicalCase && medicalCase.uuid}
        />
        {showUploadFileModal && (
          <UploadFileDialog
            open={showUploadFileModal}
            onClose={toggleUploadFileModal}
            uuid={medicalCase && medicalCase.uuid}
            type="medicalCaseFile"
          />
        )}
        {!!selectedFile && (
          <DeleteFileDialog
            open={showDeleteFileModal}
            onClose={handleCloseDeleteFile}
            file={selectedFile}
            uuid={medicalCase && medicalCase.uuid}
            type="medicalCaseFile"
          />
        )}
        <DeleteCaseDialog
          open={showDeleteModal}
          onClose={toggleDeleteModal}
          medicalCase={medicalCase}
        />
        <VirtualVisitAddModal
          open={showVirtualVisitModal && hasEmail}
          onClose={toggleVirtualVisitModal}
          openShare={() => setShowShareModal(true)}
          medicalCase={medicalCase}
          isInvited={isInvited}
          setIsInvited={setIsInvited}
        />
        <InvitePatientOptionDialog
          open={showVirtualVisitModal && !hasEmail}
          patient={medicalCase.patient}
          onClose={toggleVirtualVisitModal}
          setHasEmail={setHasEmail}
          setIsInvited={setIsInvited}
        >
          <InvitePatientWarning patient={medicalCase.patient} />
        </InvitePatientOptionDialog>

      </Navbar>
      <Container maxWidth="lg" className="article">
        <ScrollableContainer padding="1.25em 0 0">
          <PageContent>
            <Grid container spacing={1} className={classes.out}>
              <MedicalCaseMainInfo medicalCase={medicalCase} />
              <MedicalCaseShareInfo medicalCase={medicalCase} />
            </Grid>

            <ArchivesWrapper>
              {/* <CaseTimeline medicalCase={medicalCase} /> */}
              {/* <CaseAnamnesis medicalCase={medicalCase} /> */}
              {/* <Explorations values={[]} /> */}
              {/* <LaboratoryResults results={[]} /> */}
              <CaseDicomStudies medicalCase={medicalCase} />
              <CaseMedicalFiles files={medicalCaseFiles} deleteRequest={deleteFileRequest} />
              {virtualVisitOption && <CaseVirtualVisits medicalCase={medicalCase} virtualVisits={virtualVisits} />}
            </ArchivesWrapper>

            <Grid container spacing={1}>
              <Grid item xs={12}>
                {/* <Grid container spacing={2}> */}
                {/*  <Grid item xs={12} sm={8}> */}
                {/*    <Paper elevation={2} className={classes.wrapper}> */}
                {/*      <Box className={classes.box}> */}
                {/*        <Grid container spacing={1}> */}
                {/*          <Grid item className={classes.accordionMain}> */}
                {/*            <CaseDiagnostics medicalCase={medicalCase} /> */}
                {/*            <CaseInterventions medicalCase={medicalCase} /> */}
                {/*          </Grid> */}
                {/*        </Grid> */}
                {/*      </Box> */}
                {/*    </Paper> */}
                {/*  </Grid> */}
                {/*  <Grid item xs={12} sm={4}> */}
                {/*    <Paper elevation={2} className={classes.wrapper}> */}
                {/*      <Box className={classes.box}> */}
                {/*        <Grid container spacing={1}> */}
                {/*          <Grid item className={classes.accordionSecondary}> */}
                {/*            <CaseMedications medicalCase={medicalCase} /> */}
                {/*            <CaseRelated medicalCase={medicalCase} /> */}
                {/*          </Grid> */}
                {/*        </Grid> */}
                {/*      </Box> */}
                {/*    </Paper> */}
                {/*  </Grid> */}
                {/* </Grid> */}

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Paper elevation={2} className={classes.wrapper}>
                      <Box className={classes.comments}>
                        <Grid container spacing={1}>
                          <Grid item className={classes.accordionStudies}>
                            <CaseComments medicalCase={medicalCase} refetch={refetch} />
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper elevation={2} className={classes.wrapper}>
                      <Box className={classes.comments}>
                        <Grid container spacing={1}>
                          <Grid item className={classes.accordionStudies}>
                            <CaseComments medicalCase={medicalCase} refetch={refetch} toPatient />
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </PageContent>
        </ScrollableContainer>
      </Container>
    </ArticleContainerUI>
  );
};
