import React from 'react';
import { useTranslation } from 'react-i18next';

export const Translate = ({ text }) => {
  const { t } = useTranslation();

  return (
    <span>
      {t(text)}
    </span>
  );
};
