import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';

import { Box, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Router } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';

import { PageListContent } from '../Common/styled/PageContent';
import Loading from '../Common/Loading';
import { CardWrapperUI } from '../Common/styled/CardWrapperUI';
import TableCollapse from '../../componentsUI/TableCollapse';
import ItemCollapse from '../../componentsUI/itemCollapse';
import { Span } from '../Common/styled/Text';
import CaseDetails from '../../pages/case';
import { GET_RECENT_MEDICAL_CASES } from '../../graphql/queries';
import { CAPITALIZED, DATE_FORMAT, GetFormat, PATIENT_NAME_CAPITALIZED, SPECIALTY } from '../../utils/functions';
import { AlertWrapperUI, GraphQLAlertUI } from '../../componentsUI/Alert';
import { networkErrorParse } from '../../utils/ErrorGraphQLUtils';

const useStyles = makeStyles(() => ({
  secondary: {
    marginTop: -2,
    '& .MuiTypography-root:nth-child(1)': {
      fontSize: '1.0625em',
    },
    '& .MuiTypography-root:nth-child(2)': {
      marginLeft: 8,
      fontSize: '.875em',
      fontWeight: 700,
    },
  },
}));

const fieldNames = [
  { label: 'medical.case', id: 'node.title', width: 210, headerOnly: true },
  { label: 'first.name', id: 'node.patient.name', width: 90, format: CAPITALIZED, headerOnly: true },
  { label: 'last.name', id: 'node.patient.surname', width: 120, format: CAPITALIZED, headerOnly: true },
  { label: 'specialty', id: 'node.specialty', width: 0, format: SPECIALTY },
  { label: 'subspecialty', id: 'node.subspecialty', width: 0 },
  { label: 'case.created', id: 'node.createdAt', width: 120, format: DATE_FORMAT, align: 'center', headerOnly: true },
  { label: 'last.update', id: 'node.updatedAt', width: 0, format: DATE_FORMAT },
];

export const DashboardCasesView = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const hospital = useSelector((state) => state.hospital);
  const [selected, setSelected] = useState([]);
  const [expanded, setExpanded] = useState(-1);

  const { loading, error, data, refetch } = useQuery(
    GET_RECENT_MEDICAL_CASES,
    {
      variables: {
        hospitalUuid: hospital.uuid,
        first: 20,
        orderBy: {
          field: 'CREATED_AT',
          direction: 'DESC',
        },
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const errorMessage = networkErrorParse(error);

  const handleGoto = (evt, index) => {
    evt.stopPropagation();
    const iMedicalCase = data.medicalCases.edges[index].node;
    navigate(`/case/${iMedicalCase.uuid}`);
  };

  const getSubTitle = (item) => {
    if (!item) return '';

    return (
      <Box className={classes.secondary}>
        <Typography component="span">
          {GetFormat(item.node.patient, PATIENT_NAME_CAPITALIZED)}
        </Typography>
        <Typography component="span">
          {GetFormat(item.node.createdAt, DATE_FORMAT)}
        </Typography>
      </Box>
    );
  };

  if (errorMessage) {
    return (
      <AlertWrapperUI>
        <GraphQLAlertUI severity="error" title="Error" refetch={refetch}>{t(errorMessage)}</GraphQLAlertUI>
      </AlertWrapperUI>
    );
  }

  return (
    <Container maxWidth="lg" className="article">
      <PageListContent>
        {
          loading
            ? <Loading />
            : (
              <CardWrapperUI>
                {
                  (!!data.medicalCases && data.medicalCases.edges.length > 0)
                    ? (
                      <TableCollapse
                        fieldNames={fieldNames}
                        items={data.medicalCases.edges}
                        GetTitle={(item) => item.node && item.node.title}
                        GetSubtitle={getSubTitle}
                        GetCollapse={ItemCollapse}
                        selected={selected}
                        setSelected={setSelected}
                        handleGoto={handleGoto}
                        expanded={expanded}
                        setExpanded={setExpanded}
                      />
                    )
                    : <Span>{t('no.recent.cases')}</Span>
                }
              </CardWrapperUI>
            )
        }
      </PageListContent>

      <Router>
        <CaseDetails path="/case/:id" />
      </Router>
    </Container>
  );
};
