import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';

import InfoAccordion from '../../../componentsUI/InfoAccordion';
import { ListStudyCard, NoStudies } from '../../Common/StudyCard/StudyCard';

export const CaseDicomStudies = ({ medicalCase }) => {
  const { t } = useTranslation();

  const MedicalCaseStudies = () => (
    !!medicalCase.studies && medicalCase.studies.length
      ? medicalCase.studies.map((study) => (
        <ListStudyCard
          caseId={medicalCase.uuid}
          study={study}
          key={study.uuid}
        />
      ))
      : <Box style={{ margin: '.5em 1em' }}><NoStudies /></Box>
  );

  return <InfoAccordion title={t('studies')} Item={MedicalCaseStudies} expanded={!!medicalCase.studies.length} />;
};

export default CaseDicomStudies;
