import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { Close, FolderShared } from '@material-ui/icons';
import { Box, Container, Grid, Paper } from '@material-ui/core';

import { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from '../../utils/ObjectUtils';
import { CaseComments } from './comments/CaseComments';

import { ScrollableContainer } from '../Common/ScrollableContainer';
import { SectionBar } from '../../componentsUI/SectionBar';
import { PageContent } from '../Common/styled/PageContent';
import { MedicalCaseMainInfo } from './MedicalCaseMainInfo';
import useStyles from './styled/CaseMakeStyles';
import CaseDicomStudies from './archives/CaseDicomStudies';
import CaseMedicalFiles from './archives/CaseMedicalFiles';
import CaseVirtualVisits from './archives/CaseVirtualVisits';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import Loading from '../Common/Loading';
import ArticleContainerUI from '../Common/styled/ArticleContainerUI';
import { setHospitalEnvironment } from '../../utils/HospitalUtils';
import { AlertUI, AlertWrapperUI } from '../../componentsUI/Alert';

export default ({ medicalCase, refetch, errorMessage }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const context = useContext(ThemeContext);
  const dispatch = useDispatch();
  const currentHospital = useSelector((state) => state.hospital);

  if (currentHospital && currentHospital.uuid !== medicalCase.patient.hospital.uuid) {
    setHospitalEnvironment({ uuid: medicalCase.patient.hospital.uuid, dispatch, context });
  }

  const handleBack = () => {
    navigate('/cases');
  };

  const buttons = [
    { name: 'go.back', icon: Close, variant: 'outlined', data: medicalCase, handleClick: () => handleBack() },
  ];

  const backButton = {
    label: t('back.to.medical.cases'),
    icon: FolderShared,
    onClick: handleBack,
  };

  const getApplicationPath = (d) => {
    if (isEmpty(d)) return null;
    return {
      medicalCase: {
        name: d.title,
        link: `/case/${d.uuid}`,
      },
    };
  };

  const ArchivesWrapper = ({ children }) => (
    <Grid container spacing={1} className={classes.out}>
      <Grid item xs={12} lg={10} xl={7}>
        <Paper elevation={2}>
          <Box className={classes.box}>
            <Grid container spacing={1}>
              <Grid item className={classes.accordion}>
                {children}
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );

  if (errorMessage) {
    return (
      <AlertWrapperUI>
        <AlertUI severity="error" title="Error">{t(errorMessage)}</AlertUI>
      </AlertWrapperUI>
    );
  }

  if (!medicalCase) return <Loading />;

  return (
    <ArticleContainerUI>
      <Navbar className={classes.subheader}>
        <SectionBar title="case.info" items={buttons} back={backButton} breadcrumbs={getApplicationPath(medicalCase)} />
      </Navbar>
      <Container maxWidth="lg" className="article">
        <ScrollableContainer padding="1.25em 0 0">
          <PageContent>

            <Grid container spacing={1} className={classes.out}>
              <MedicalCaseMainInfo medicalCase={medicalCase} />
              {/* <MedicalCaseShareInfo medicalCase={medicalCase} /> */}
            </Grid>

            <ArchivesWrapper>
              <CaseDicomStudies medicalCase={medicalCase} />
              <CaseMedicalFiles files={medicalCase && medicalCase.medicalCaseFiles} />
              <CaseVirtualVisits medicalCase={medicalCase} virtualVisits={medicalCase && medicalCase.virtualVisits} />
            </ArchivesWrapper>

            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={9}>
                    <Paper elevation={2} className={classes.commentWrapper}>
                      <Box className={classes.comments}>
                        <Grid container spacing={1}>
                          <Grid item className={classes.accordionStudies}>
                            <CaseComments medicalCase={medicalCase} refetch={refetch} toPatient />
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </PageContent>
        </ScrollableContainer>
      </Container>
    </ArticleContainerUI>
  );
};
