import React from 'react';
import { Box } from '@material-ui/core';

import { useListStyles } from './styled/StudiesViewMakeStyles';
import { DATE_FORMAT, GetFormat } from '../../utils/functions';

export const GetTitle = (item) => (
  `${item.node.title}`
);

export const GetSubTitle = (item) => {
  const classes = useListStyles();
  const node = item && item.node;
  const formattedDate = node && node.createdAt && GetFormat(node.createdAt, DATE_FORMAT);
  const formattedModalities = node && node.modalities && Array.isArray(node.modalities) ? node.modalities.join(', ') : node.modalities;

  return (
    <>
      <Box className={classes.studyDate}>
        {formattedDate}
      </Box>
      <Box className={classes.studyInfo}>
        {`${item.node.numberOfSeries} Series · ${item.node.numberOfInstances} Images · ${formattedModalities}` }
      </Box>
    </>
  );
};
