/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';

import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide} from '@material-ui/core';
import Loading from '../Common/Loading';
import { parseGraphqlErrors } from '../../utils/FormikUtils';
import { useStyles } from './styled/ProtocolMakeStyles';
import { GET_PROTOCOLS, CREATE_PROTOCOL } from '../../queries/Protocols/Protocols';
import { ProtocolFormDialog } from './ProtocolFormDialog';
import {AlertUI} from "../../componentsUI/Alert";

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export const ProtocolAddDialog = ({ open, onClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const hospitalUuid = useSelector((state) => state.hospital.uuid);
  const [serverError, setServerError] = useState(false);
  const [resetAction, setResetAction] = useState(0);

  const [createProtocol, { loading: updating }] = useMutation(
    CREATE_PROTOCOL,
    {
      onCompleted: () => {
        onClose();
      },
      refetchQueries: [{ query: GET_PROTOCOLS, variables: { hospitalUuid } }],
      awaitRefetchQueries: true,
    },
  );

  const handleSubmit = async (values, { setErrors, resetForm }) => {
    setServerError(false);
    try {
      await createProtocol({ variables: { input: { ...values, ...{ hospitalUuid } } } }).then(resetForm());
    } catch (errors) {
      const formikErrors = parseGraphqlErrors(errors.graphQLErrors, t);
      if (
        Object.keys(formikErrors).length === 0
        && formikErrors.constructor === Object
      ) {
        setServerError(true);
      } else {
        setErrors(formikErrors);
      }
    }
  };

  const handleCancel = () => {
    setResetAction(resetAction + 1);
    onClose();
  };

  const formId = 'add-protocol-form';

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle className={classes.title}>{t('add.protocol')}</DialogTitle>
      <DialogContent>
        <ProtocolFormDialog
          reset={resetAction}
          setReset={setResetAction}
          formId={formId}
          handleSubmit={handleSubmit}
          submitting={updating}
          serverError={serverError}
        />
        {updating && <Loading />}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className={classes.button}
          color="primary"
          onClick={handleCancel}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          type="submit"
          form={formId}
        >
          {t('create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
