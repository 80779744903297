import styled from 'styled-components';

export const CommentsWrapper = styled.div``;

export const CommentBox = styled.div`
  display: table; 
  width: 100%;
  word-break: break-all;

  // Quill: Rich content editor styling
  .quill {
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: ${(props) => props.theme.baseBoxShadow};

    .ql-snow {
      font-family: inherit;
      font-size: inherit;
    }

    .ql-toolbar {
      border: none;
      border-bottom: ${(props) => props.theme.cardBorder};

      .ql-stroke {
        stroke: ${(props) => props.theme.colors.black};
      }
    }

    .ql-container {
      border: none;

      .ql-editor {
        min-height: 130px;
      }
    }
  }
`;

export const UserCommentWrapper = styled.div`
  padding: 2%;
  border-bottom: ${(props) => props.theme.cardBorder};
`;

export const CommentHeader = styled.div`  
  display: flex;

  div {
    margin-left: 1.5%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      margin: 0;
    }
  }
`;

export const CommentBody = styled.div`

`;

export const CommentImagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CommentImage = styled.div`
  width: 150px;
  height: 90px;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 1%;
`;
