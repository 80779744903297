import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';

import { Container, Grid } from '@material-ui/core';
import { InfoTwoTone, Web } from '@material-ui/icons';

import { SectionBar } from '../../componentsUI/SectionBar';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { ContainerUI } from '../Common/styled/ContainerUI';
import ScrollableContainer from '../Common/ScrollableContainer';
import { CasesView } from './Cases/CasesView';
import { VirtualVisitsView } from './VirtualVisit/VirtualVisitView';
import { PatientStudiesView } from './Studies/PatientStudiesView';
import { useDashboardStyles } from './Styles/DashboardStyles';
import Loading from '../Common/Loading';
import { webViewerEndpoints } from '../../../config';

export const DashboardView = () => {
  const classes = useDashboardStyles();
  const hospital = useSelector((state) => state.hospital);
  const [selectedCase, setSelectedCase] = useState(null);
  const [selectedVisit, setSelectedVisit] = useState(null);
  const [selectedStudy, setSelectedStudy] = useState(null);

  const selectCase = (medicalCase) => {
    setSelectedCase(medicalCase);
    setSelectedVisit(null);
    setSelectedStudy(null);
  };

  const selectVisit = (virtualVisit) => {
    setSelectedCase(null);
    setSelectedVisit(virtualVisit);
    setSelectedStudy(null);
  };

  const selectStudy = (dicomStudy) => {
    setSelectedCase(null);
    setSelectedVisit(null);
    setSelectedStudy(dicomStudy);
  };

  const isBrowser = () => typeof window !== 'undefined';

  const handleOpen = (evt) => {
    evt.stopPropagation();

    if (selectedCase) {
      navigate(`/case/${selectedCase.uuid}`);
    }

    if (selectedVisit) {
      navigate(`/virtual-visit/${selectedVisit.uuid}`);
    }

    if (selectedStudy) {
      navigate(`/study/${selectedStudy.uuid}`);
    }
  };

  const openInWebViewer = (evt) => {
    evt.stopPropagation();

    if (!isBrowser) return;

    window.open(`${webViewerEndpoints.getStudy}${selectedStudy.studyInstanceUid}`, '_blank');
  };

  const openButtonDisabled = !selectedCase && !selectedVisit && !selectedStudy;
  const buttons = [
    { name: 'see.detail', icon: Web, handleClick: openInWebViewer, disabled: !selectedStudy },
    { name: 'see.detail', icon: InfoTwoTone, handleClick: handleOpen, disabled: openButtonDisabled },
  ];

  return (
    <ContainerUI>
      <Navbar>
        <SectionBar title="recent.patient.data.in.hospital" items={buttons} />
      </Navbar>
      <Container maxWidth="lg" className="article">
        <ScrollableContainer>
          {!hospital && <Loading />}
          <Grid container spacing={2} className={classes.flex}>
            {hospital && hospital.menuOptionMedicalCases && (
              <Grid item xs>
                <CasesView
                  selectedCase={selectedCase}
                  selectCase={selectCase}
                />
              </Grid>
            )}
            {hospital && hospital.menuOptionVirtualVisits && (
              <Grid item xs>
                <VirtualVisitsView
                  selectedVisit={selectedVisit}
                  selectVisit={selectVisit}
                />
              </Grid>
            )}
            {hospital && hospital.menuOptionStudies && (
              <Grid item xs>
                <PatientStudiesView
                  selectedStudy={selectedStudy}
                  selectStudy={selectStudy}
                />
              </Grid>
            )}
          </Grid>
        </ScrollableContainer>
      </Container>
    </ContainerUI>
  );
};
