import React from 'react';

import { Icon } from '../styled/Icon';
import defaultprofilePhoto from '../../../images/patient-profile.png';
import { GreyLabel } from '../styled/GreyLabel';
import { FromNow, LocaleDateTime } from '../FormattedDate';
import { HighlightedText } from '../styled/HighlightedText';

export const CommentHeaderContent = ({ comment, fromNow }) => (
  <>
    <Icon src={comment.author.profilePhoto || defaultprofilePhoto} size={40} />
    <div>
      <p>
        {`${comment.author.name} ${comment.author.surname}`}
      </p>
      <GreyLabel>
        <strong style={{ fontSize: '.9375em' }}>
          <LocaleDateTime date={comment.createdAt} />
        </strong>
        {fromNow && (
          <HighlightedText>
            ...
            <small><FromNow date={comment.createdAt} bold /></small>
          </HighlightedText>
        )}
      </GreyLabel>
    </div>
  </>
);
