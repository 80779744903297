import React, { useContext } from 'react';

import { ThemeContext } from 'styled-components';
import PulseLoader from 'react-spinners/PulseLoader';

import { LoaderWrapper } from './styled/LoaderStyles';

export const Loader = ({ children, isLoading = false }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <LoaderWrapper>
      { children }
      <PulseLoader
        size={10}
        color={themeContext.colors.primary}
        loading={isLoading}
      />
    </LoaderWrapper>
  );
};
