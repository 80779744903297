import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, Grid, Typography } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';

import { Thumb, ThumbInner, ThumbsContainer, PreviewImg } from './styled/StyledPreview';
import { StyledDropzone } from './styled/StyledDropzone';
import useStyles from './styled/DropzoneStyles';
import { Span } from '../styled/Text';
import { IconButtonUI } from '../../../componentsUI/IconButton';
import 'react-image-crop/dist/ReactCrop.css';

const defaultAccept = 'image/jpg, image/gif, image/png, image/jpeg';
const defaultMaxSize = 100000000; // bytes (100MB)

export function Dropzone(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    setFile,
    clearFile,
    currentFileUrl,
    accept,
    maxSize,
    disabled = false,
    answered = false } = props;
  const styles = useStyles();
  const [previewUrl, setPreviewUrl] = useState(currentFileUrl || null);
  const [previewFileName, setPreviewFileName] = useState('');

  const imageExtensions = ['jpg', 'gif', 'png', 'jpeg'];
  // const videoExtensions = ['mp4', '3gp', '3gpp', 'mov'];

  function getExtension(urlString) {
    try {
      const url = new URL(urlString);
      return url.pathname.substr(url.pathname.lastIndexOf('.') + 1);
    } catch (error) {
      return null;
    }
  }

  const [isImage, setIsImage] = useState(imageExtensions.includes(getExtension(previewUrl)));

  function openViewer(images) {
    dispatch({ type: 'SET_VIEWER_IMAGES', viewerImages: { viewerImages: images.map((image) => ({ src: image })) } });
    dispatch({ type: 'TOGGLE_VIEWER', showViewer: { showViewer: true } });
  }

  const clearImage = () => {
    setPreviewUrl(null);
    setPreviewFileName('');
    clearFile();
  };

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (disabled) return;

    if (acceptedFiles && acceptedFiles.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        if (acceptedFiles[0].type.includes('image')) {
          setIsImage(true);
          setPreviewUrl(reader.result);
        } else {
          setIsImage(false);
          setPreviewFileName(acceptedFiles[0].name);
        }
      });

      reader.readAsDataURL(acceptedFiles[0]);
      setFile(acceptedFiles[0]);
    }

    if (rejectedFiles && rejectedFiles.length > 0) {
      const currentMaxSize = maxSize || defaultMaxSize;
      const acceptedTypes = accept || defaultAccept;
      const acceptedTypesArray = acceptedTypes.split(',').map((item) => item.trim());
      if (!acceptedTypesArray.includes(rejectedFiles[0].type)) toast(t('invalid.file.type'), { className: 'toast-error' });
      else if (rejectedFiles[0].size > currentMaxSize) toast(t('invalid.file.size', { maxSize: currentMaxSize / 1000000 }), { className: 'toast-error' });
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: accept === 'all' ? null : accept || defaultAccept, multiple: false, maxSize: maxSize || defaultMaxSize });
  const dropzoneStyle = disabled ? { color: '#CCC' } : {};

  return (
    <Grid container spacing={2} className={styles.root}>
      {!answered && (
        <Grid item xs={6}>
          <StyledDropzone>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input
                value=""
                disabled={disabled}
                {...getInputProps({ id: 'profileImage', name: 'profileImage' })}
              />
              <span style={dropzoneStyle}>
                <Typography>{t('drag.drop')}</Typography>
                <Typography>{t('or')}</Typography>
                <Typography><b>{t('click.choose.file')}</b></Typography>
              </span>
            </div>
          </StyledDropzone>
        </Grid>
      )}
      <Grid item xs={6}>
        <ThumbsContainer>
          {isImage && previewUrl && (
          <Thumb>
            <ThumbInner>
              <PreviewImg
                src={previewUrl}
                alt="file"
                onClick={() => openViewer([previewUrl])}
              />
            </ThumbInner>
          </Thumb>
          )}
          {!(isImage && previewUrl) && previewFileName && <Span>{previewFileName}</Span>}
          {!(isImage && previewUrl) && !previewFileName && (previewUrl
            ? <a href={previewUrl} target="_blank" rel="noopener noreferrer">{t('view.file')}</a>
            : '')}
        </ThumbsContainer>
      </Grid>
      {typeof clearFile === 'function' && !answered && (
        <Box className={styles.control}>
          <IconButtonUI
            title={t('clear')}
            handleClick={clearImage}
            disabled={!(isImage && previewUrl)}
            Icon={HighlightOff}
          />
        </Box>
      )}
    </Grid>
  );
}
