import styled from 'styled-components';

export const CollapsedCardWrapper = styled.div`
  padding: 4px 0;

  a {
    color: ${(props) => props.theme.colors.black};

    &:hover {
      text-decoration: none;
    }
  }
`;
