import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { FIND_USER_BY_UUID } from '../../graphql/queries';
import { Span } from './styled/Text';

const RetrieveByUser = ({ user }) => {
  const { data } = useQuery(
    FIND_USER_BY_UUID,
    {
      variables: {
        uuid: user,
      },
    },
  );

  const displayUserData = (userData) => (
    // First we check if userData is json and it contains attribute uuid
    userData.uuid ? (
      <Span>
        <b>{`${userData.name} ${userData.surname} `}</b>
        {`${userData.email}`}
      </Span>
    ) : (
      <Span>
        <b>user not reg.</b>
        {` ${userData}`}
      </Span>
    )
  );

  return (
    data && data.userByUuid ? displayUserData(data.userByUuid) : displayUserData(user)
  );
};

const parseUserData = (string) => {
  try {
    return JSON.parse(string);
  } catch (e) {
    return string;
  }
};

export const ByUser = ({ user }) => {
  if (!user) { return null; }

  const userInfo = parseUserData(user);

  return (
    userInfo.uuid ? (
      <Span>
        <b>{`${userInfo.name} `}</b>
        {`${userInfo.email}`}
      </Span>
    ) : (
      <RetrieveByUser user={userInfo} />
    )
  );
};
