import React from 'react';
import { Link } from 'gatsby';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/react-hooks';
import { Box, Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';

import { FIND_USER_PROFILE_BY_UUID } from '../../graphql/queries';
import { LinkTextButton } from '../Common/styled/TextButton';
import { GreyLabel } from '../Common/styled/GreyLabel';
import { getFormattedDate, getLocaleYears } from '../../utils/dateTimeUtils';
import { InnerDivHTML } from '../Common/InnerHTML';
import useStyles from './styled/CaseMakeStyles';
import UserInfo from '../../componentsUI/UserInfo';

const MEDICAL_CASE_ACTIVE_OPEN = 1;
const MEDICAL_CASE_ACTIVE_CLOSED = 0;

export const MedicalCaseMainInfo = ({ medicalCase }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { data } = useQuery(
    FIND_USER_PROFILE_BY_UUID,
    {
      variables: {
        uuid: medicalCase.createdBy,
      },
    },
  );

  const admin = data && data.userByUuid;
  const age = medicalCase.patient && medicalCase.patient.birthDate
    && getLocaleYears(moment(medicalCase.patient.birthDate));

  return (
    <Grid item xs={12} md={8} lg={7}>
      <Paper elevation={2}>
        <Box className={classes.case}>
          <Box className={classes.userInfo}>
            <div className={classes.adminInfo}>
              <Grid container>
                {admin && <UserInfo admin={admin} entity={medicalCase} type="medicalCase" />}
              </Grid>
            </div>
          </Box>
          <Grid container>
            <Grid item xs={8}>
              <div className={classes.patientInfo}>
                <GreyLabel>{`${t('patient')}: `}</GreyLabel>
                <Link to={`/patient/${medicalCase.patient.uuid}`}>
                  <LinkTextButton>
                    {`${medicalCase.patient.name} ${medicalCase.patient.surname}`}
                  </LinkTextButton>
                </Link>
                <strong>{age}</strong>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.caseStatus}>
                {medicalCase.active === MEDICAL_CASE_ACTIVE_OPEN && (
                  <Chip label={t('case.open')} color="primary" />
                )}
                {medicalCase.active === MEDICAL_CASE_ACTIVE_CLOSED && (
                  <Chip label={t('case.closed')} />
                )}
              </div>
            </Grid>

            <Grid item xs={11} sm={10} className={classes.indent}>
              <div className={classes.caseTitle}>{medicalCase.title}</div>
              <div className={classes.subtype}>
                <GreyLabel>{`${t('specialty')}: `}</GreyLabel>
                <span className="value">{t(medicalCase.specialty.toLowerCase())}</span>
              </div>
              <div className={classes.subtype}>
                <GreyLabel>{`${t('subspecialty')}: `}</GreyLabel>
                <span className="value">{t(medicalCase.subspecialty) || '...'}</span>
              </div>
              <div className={classes.subtype}>
                <GreyLabel>{`${t('start.of.case')}: `}</GreyLabel>
                <span className="value">{getFormattedDate({ date: medicalCase.createdAt })}</span>
              </div>
              <div className={classes.subtype}>
                <GreyLabel>{`${t('description')}: `}</GreyLabel>
              </div>
              <InnerDivHTML classes={classes.description} content={medicalCase.description || t('no.description')} />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};

export default MedicalCaseMainInfo;
