import { makeStyles } from '@material-ui/core/styles';

const hoverTableStyles = (theme, selected, isResponsive) => ({
  background: selected ? '#F0F4F9' : '#F8FBFF',
  transition: 'background .25s',
  '& $tableItemIcon': {
    color: theme.palette.primary.dark,
  },
  '& $headerCollapse': {
    color: theme.palette.primary.dark,
  },
  '& $tableItem': {
    color: theme.palette.primary.dark,
  },
  '& $goButton': {
    color: theme.palette.primary.main,
  },
  '& $entity': {
    color: selected ? theme.palette.primary.dark : theme.palette.primary.main,
  },
  ...(!isResponsive) ? {
    boxShadow: selected ? '0 3px 9px 0 rgba(0,0,0,.4)' : '0 2px 7px 0 rgba(0,0,0,.1)',
    transition: 'box-shadow .25s',
  } : {},
});

const hoverNotificationTableStyles = (theme) => ({
  backgroundColor: '#F7F7F7',
  // color: '#b70a0e',
  '& $tableItemIcon': {
    color: theme.palette.primary.main,
  },
  '& $headerCollapse': {
    color: theme.palette.primary.main,
  },
  '& $tableItem': {
    color: '#707070',
  },
});

export const useStyles = (isResponsive) => makeStyles((theme) => ({
  table: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    ...(isResponsive) ? {
      borderRadius: 5,
      boxShadow: '0 2px 7px 0 rgba(0,0,0,.1)',
      backgroundColor: 'rgba(255,255,255,.8)',
    } : {
      '& > div': {
        paddingTop: 20,
        paddingBottom: 40,
      },
    },
    '& a': {
      textDecoration: 'none',
    },
  },
  tableHeaderScroll: {
    width: '100%',
    overflow: 'hidden',
  },
  tableHeader: {
    display: 'inline-flex',
    position: 'relative',
    height: 36,
    width: 'auto',
    minWidth: '100%',
    borderBottom: '1px solid #999',
  },
  tableField: {
    cursor: 'pointer',
    display: 'inline-flex',
    lineHeight: 1.0,
    fontSize: '1.3rem',
    fontWeight: 600,
    color: '#555',
    padding: 10,
    '& span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  tableItemsScroll: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    boxSizing: 'border-box',
    position: 'relative',
    ...(!isResponsive) ? {
      paddingRight: 5,
      paddingLeft: 5,
    } : {},
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  entity: {
    width: 32,
    height: 32,
    paddingLeft: 15,
    '& .MuiSvgIcon-root': {
      height: '1.25em',
      width: '1.25em',
    },
    '@media (max-width:959px)': {
      marginTop: 14,
    },
  },
  tableItems: {
    width: 'max-content',
    minWidth: '100%',
    maxWidth: '100%',
    height: 'auto',
    ...(!isResponsive) ? {
      width: '100%',
    } : {},
  },
  tableItemIcon: {
    width: 30,
    height: 22,
    paddingLeft: 10,
    color: 'transparent',
    '@media (max-width:959px)': {
      marginTop: 12,
      paddingLeft: 6,
      marginRight: -4,
    },
    '@media (max-width:600px)': {
      paddingLeft: 2,
      marginRight: -8,
    },
  },
  itemSelected: {
    minHeight: 46,
    height: 'auto',
    backgroundColor: '#F5F8FC',
    borderBottom: '#ebebeb 1px solid',
    color: 'rgba(0, 0, 0, 0.87)',
    ...(!isResponsive) ? {
      height: 'auto',
      margin: '10px 0',
      borderRadius: 10,
      boxShadow: '0 3px 8px 0 rgba(0,0,0,.4)',
      transition: 'background .35s, box-shadow .35s',
    } : {},
    '&:hover': hoverTableStyles(theme, true, isResponsive),
    '& $tableItemIcon': {
      color: '#969696',
    },
    '& $entity': {
      color: theme.palette.primary.main,
    },
    '& .item-no-message': {
      whiteSpace: 'break-spaces',
      overflow: 'visible',
    },
  },
  item: {
    minHeight: 46,
    height: 'auto',
    borderBottom: '#ebebeb 1px solid',
    color: 'rgba(0, 0, 0, 0.87)',
    ...(!isResponsive) ? {
      background: '#fff',
      height: 'auto',
      margin: '10px 0',
      borderRadius: 10,
      boxShadow: '0 2px 7px 0 rgba(0,0,0,.1)',
      transition: 'box-shadow .35s',
    } : {},
    '& $entity': {
      color: theme.palette.primary.light,
    },
    '&:hover': hoverTableStyles(theme, false, isResponsive),
  },
  itemNotification: {
    minHeight: 46,
    height: 'auto',
    borderBottom: '#ebebeb 1px solid',
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.87)',
    ...(!isResponsive) ? {
      background: '#fff',
      height: 'auto',
      margin: '10px 0',
      borderRadius: 10,
      boxShadow: '0 2px 7px 0 rgba(0,0,0,.1)',
    } : {},
    '&:hover': hoverNotificationTableStyles(theme),
  },
  tableItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minWidth: '100%',
    minHeight: 46,
    color: theme.palette.grey.A700,
    '@media (max-width:959px)': {
      alignItems: 'flex-start',
    },
  },
  itemField: {
    cursor: 'default',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.25rem',
    fontWeight: 500,
    // color: #999;
    padding: 10,
    '& > div.ellipsis': {
      height: 16,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& > div.icon': {
      height: 20,
      color: theme.palette.primary.light,
      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
  },
  multilineItemField: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    lineHeight: 1.125,
    fontSize: '1.25rem',
    fontWeight: 500,
    padding: 10,
    whiteSpace: 'break-spaces',
    '& .message-not-read': {
      color: theme.palette.primary.dark,
    },
    '&. notification-icon': {
      fontSize: '.875em',
    },
  },
  goButtonBox: {
    position: 'absolute',
    right: 0,
    zIndex: 2,
  },
  goButton: {
    color: 'transparent',
    '&.MuiIconButton-root': {
      position: 'sticky',
      right: 8,
      padding: 6,
    },
    margin: '10px',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  collapse: {
    width: '100%',
  },
  hScroll: {
    display: 'flex',
    height: 1,
  },
  headerCollapse: {
    cursor: 'default',
    display: 'inline-grid',
    flexDirection: 'column',
    alignSelf: 'center',
    color: 'rgba(0, 0, 0, 0.8)',
    fontSize: '1.3rem',
    fontWeight: 450,
    lineHeight: 1.33,
    margin: '10px 10px',
    letterSpacing: '0.01071em',
  },
  headerNotificationCollapse: {
    cursor: 'pointer',
    display: 'inline-grid',
    flexDirection: 'column',
    alignSelf: 'center',
    color: 'rgba(0, 0, 0, 0.8)',
    fontSize: '1.3rem',
    fontWeight: 450,
    lineHeight: 1.33,
    margin: '10px 10px',
    letterSpacing: '0.01071em',
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&.message': {
      whiteSpace: 'break-spaces',
      overflow: 'visible',
    },
    '&.message-not-read': {
      color: theme.palette.primary.dark,
    },
    '& .MuiSvgIcon-root': {
      margin: '0 6px -4px -2px',
      color: theme.palette.primary.light,
    },
  },
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 4,
    color: 'rgba(0, 0, 0, 0.5)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& svg.MuiSvgIcon-root': {
      color: 'rgba(0, 0, 0, 0.3)',
      fontSize: '1.875rem',
      marginRight: '.625rem',
    },
    '&.message-not-read svg.MuiSvgIcon-root': {
      color: theme.palette.primary.dark,
    },
  },
  orderIcon: {
    marginTop: -4,
    marginLeft: 5,
  },
  notificationIconWrapper: {
    margin: '0 1.25rem 0 .5rem',
  },
}))();
