import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from './styles/InfoAccordion';

const InfoAccordion = ({ Item, title, expanded }) => {
  const classes = useStyles();

  return (
    <Accordion
      defaultExpanded={expanded}
      classes={{
        root: classes.root,
        summary: classes.accordion,
      }}
    >
      <AccordionSummary
        className={classes.summary}
        expandIcon={<ExpandMoreIcon />}
        aria-label="Expand"
        aria-controls="additional-actions1-content"
        id="additional-actions1-header"
      >
        <Typography className={classes.title}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails auto="true" className={classes.listcards}>
        <Item className={classes.card} />
      </AccordionDetails>
    </Accordion>
  );
};

export default InfoAccordion;
