import React from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';

import { Paper, Typography } from '@material-ui/core';

import { isEmpty, sortGraphQueryList } from '../../../utils/ObjectUtils';
import Loading from '../../Common/Loading';

import { GET_PATIENT_MEDICAL_CASES } from '../../../graphql/queries';
import TableCollapse from '../../../componentsUI/TableCollapse';
import { CaseCollapse } from '../../Case/CaseCollapse';
import { ACTIVE_CASE, DATE_FORMAT, GetFormat } from '../../../utils/functions';
import { networkErrorParse } from '../../../utils/ErrorGraphQLUtils';
import { AlertWrapperUI, GraphQLAlertUI } from '../../../componentsUI/Alert';
import { useDashboardStyles } from '../Styles/DashboardStyles';

const fieldNames = [
  { label: 'medical.case', id: 'title', field: 'TITLE', direction: 'ASC', width: 370 },
  { label: 'case.created', id: 'createdAt', field: 'CREATED_AT', direction: 'DESC', width: 120, format: 'DATEFORMAT' },
];

export const CasesView = ({ selectedCase, selectCase }) => {
  const { t } = useTranslation();
  const classes = useDashboardStyles();

  const orderByField = 'CREATED_AT';
  const orderByDirection = 'DESC';

  const user = useSelector((state) => state.userInterface.user);
  const { loading, error, data, refetch } = useQuery(
    GET_PATIENT_MEDICAL_CASES, {
      variables: {
        uuid: user.patientUuid,
        first: 20,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const errorMessage = networkErrorParse(error);

  const medicalCases = data && data.patient && data.patient.medicalCases
    && data.patient.medicalCases.length > 0 && sortGraphQueryList(
    data.patient.medicalCases,
    fieldNames,
    orderByField,
    orderByDirection,
  ).slice(0, 5);

  const handleViewCases = (evt) => {
    evt.stopPropagation();

    navigate('/cases');
  };

  const handleGotoCase = (evt, index) => {
    evt.stopPropagation();

    navigate(`/case/${medicalCases[index].uuid}`);
  };

  const defined = (item) => (item === 0 || item !== null);

  const selected = (medicalCases && selectedCase)
    ? medicalCases.map((item, key) => (item.uuid === selectedCase.uuid ? key : null)).filter(defined)
    : [];

  const handleSelectCase = (sel) => {
    if (!sel) return;
    if (!sel.length) {
      selectCase(null);
      return;
    }

    selectCase(selected.length && sel[0] === selected[0] ? null : medicalCases[sel[0]]);
  };

  const getCaseTitle = (item) => (
    `${item.title}`
  );

  const getCaseSubTitle = (item) => (
    `${GetFormat(item.createdAt, DATE_FORMAT)} - ${GetFormat(item.active, ACTIVE_CASE)}`
  );

  if (errorMessage) {
    return (
      <AlertWrapperUI>
        <GraphQLAlertUI severity="warning" title="Error" refetch={refetch}>
          {t(errorMessage)}
        </GraphQLAlertUI>
      </AlertWrapperUI>
    );
  }

  return (
    <>
      <Paper elevation={2} className={classes.title} onClick={handleViewCases}>
        <Typography>
          {t('cases')}
        </Typography>
      </Paper>
      { loading && isEmpty(data) && <Loading /> }
      { medicalCases
      && (
        <TableCollapse
          responsive={false}
          fieldNames={fieldNames}
          items={medicalCases}
          GetTitle={getCaseTitle}
          GetSubtitle={getCaseSubTitle}
          GetCollapse={CaseCollapse}
          selected={selected}
          setSelected={handleSelectCase}
          handleGoto={handleGotoCase}
        />
      )}
    </>
  );
};
