import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import InfoAccordion from '../../../componentsUI/InfoAccordion';
import ArchivesTableCollapse from '../../../componentsUI/ArchivesTableCollapse';
import { GetFormat } from '../../../utils/functions';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '0 2em 1em',
  },
  datetime: {
    margin: '.25em .375em 0',
    fontSize: '0.75em',
    color: theme.palette.grey.A200,
  },
  noItem: {
    margin: '.25em',
    fontSize: '.875em',
    color: '#909090',
  },
}));

const LaboratoryResultCollapse = ({ item }) => {
  const laboratoryResult = item.node ? item.node : item;
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <span><small>{laboratoryResult.title}</small></span>
    </div>
  );
};

const LaboratoryResults = ({ results }) => {
  const [selected, setSelected] = useState([]);
  const { t } = useTranslation();
  const classes = useStyles();

  const getTitle = (item) => (
    `${item.title}`
  );

  const getDateTime = (item) => (
    <div className={classes.datetime}>
      {GetFormat(item.createdAt, 'DATEFORMAT')}
    </div>
  );

  const handleGoto = (evt, index) => {
    evt.stopPropagation();
    const indexCase = results[index];

    navigate(`/case/${indexCase.uuid}`);
  };

  const emptyList = !results || !results.length;
  const LaboratoryResultList = () => {
    if (emptyList) return <div style={{ margin: '.5em 1em' }}>{t('no.laboratory.results')}</div>;

    const sortedCases = [...results].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    return (
      <ArchivesTableCollapse
        responsive={false}
        fieldNames={null}
        items={sortedCases}
        GetTitle={getTitle}
        GetSubtitle={getDateTime}
        GetCollapse={LaboratoryResultCollapse}
        selected={selected}
        setSelected={setSelected}
        handleGoto={handleGoto}
      />
    );
  };

  return (
    <InfoAccordion title={t('laboratory.results')} Item={LaboratoryResultList} expanded={!emptyList} />
  );
};

export default LaboratoryResults;
