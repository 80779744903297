import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';

import { GetValueToPath } from '../utils/functions';

const useStyles = makeStyles((theme) => ({
  select: {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiInputBase-input': {
      fontFamily: 'Muli',
      color: '#606060',
    },
  },
  helperTextRoot: {
    color: theme.palette.error.main,
  },
}));

export const TextSelectAutocompleteUI = (
  {
    name,
    label,
    options,
    props,
    disabled = false,
    classes = useStyles(),
  },
) => {
  const { values, errors } = props;
  const { t } = useTranslation();
  const { select } = useStyles();
  const styles = classes ? `${select} ${classes.root}` : select;
  const value = GetValueToPath(values, name) || '';

  const handleChange = (event, val) => {
    props.setFieldValue(name, val);
  };

  return (
    <Autocomplete
      name={name}
      options={options}
      getOptionLabel={(opt) => opt.label || ''}
      getOptionSelected={(opt, val) => opt.label === val.label}
      helpertext={errors[name] && errors[name]}
      value={value || ''}
      onChange={handleChange}
      className={styles}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t(label)}
          value={value}
          name={name}
        />
      )}
    />
  );
};
