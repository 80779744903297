import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: 15,
      width: '100%',
      maxWidth: 800,
      height: '100%',
      maxHeight: '80%',
    },
  },
  title: {
    margin: '8px 0 16px',
    fontSize: '1.25em',
    fontWeight: '600',
    color: theme.palette.primary.main,
  },
  button: {
    marginTop: 6,
    marginRight: 12,
    padding: '4px 12px',
    minWidth: 125,
    fontWeight: 600,
  },
  optionButton: {
    marginTop: 4,
    marginRight: 12,
    padding: '2px 8px 2px 4px',
    borderRadius: 4,
    minWidth: 125,
    fontWeight: 500,
    fontSize: '1.3125rem',
    textTransform: 'initial',
    '& svg.MuiSvgIcon-root': {
      marginRight: 4,
    },
  },
  fieldset: {
    border: 'none',
  },
  definition: {
    '& .MuiGrid-root': {
      '& .MuiFormControl-root': {
        width: '100%',
        minHeight: 67,
      },
    },
  },
  questions: {
    '& .MuiGrid-root': {
      '& .MuiFormControl-root': {
        width: '100%',
        marginBottom: 6,
      },
      '& .MuiOutlinedInput-input': {
        padding: '12px 16px 9px',
      },
    },
  },
  question: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '& .MuiTypography-root': {
      textAlign: 'right',
      width: '100%',
      height: 17,
      margin: '0 5px',
      padding: '9px 18px 10px',
      border: `solid ${theme.palette.primary.light} 1px`,
      borderRadius: 6,
      fontWeight: 600,
      color: 'white',
      backgroundColor: theme.palette.primary.light,
      backgroundImage: `linear-gradient(90deg, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`,
      [theme.breakpoints.down('xs')]: {
        padding: '9px 9px 10px',
      },
    },
    '& .MuiButtonBase-root': {
      padding: 10,
      marginTop: -2,
      [theme.breakpoints.down('xs')]: {
        marginRight: -4,
      },
    },
  },
  questionInput: {
    height: 4,
    width: '100%',
    margin: '-4px 0 6px',
    backgroundColor: theme.palette.primary.light,
  },
  option: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '& span.icon-wrapper': {
      marginLeft: -25,
      paddingTop: 14,
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 8,
        marginLeft: -18,
      },
    },
    '& .MuiButtonBase-root': {
      marginLeft: -8,
      padding: 6,
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 8,
        marginLeft: -25,
      },
    },
  },
  helperTextRoot: {
    color: theme.palette.error.main,
  },
}));

export default useStyles;
