import { makeStyles } from '@material-ui/core/styles';

const hoverTableStyles = (theme) => ({
  backgroundColor: '#2020200a',
  '& $tableItemIcon': {
    color: theme.palette.primary.main,
  },
  '& $tableItem': {
    color: theme.palette.primary.main,
  },
  '& $goButton': {
    color: theme.palette.primary.main,
  },
});

export const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 2,
    boxShadow: '0 2px 7px 0 rgba(0,0,0,.1)',
    backgroundColor: 'rgba(255,255,255,.8)',
  },
  row: {
    position: 'relative',
    display: 'flex',
    width: '100%',
  },
  actions: {
    position: 'absolute',
    right: 0,
  },
  tableItems: {
    width: 'max-content',
    minWidth: '100%',
    height: 'auto',
  },
  tableItemIcon: {
    width: 30,
    height: 22,
    paddingLeft: 10,
    color: 'transparent',
  },
  itemSelected: {
    minHeight: 46,
    height: 'auto',
    backgroundColor: '#2020200a',
    borderBottom: '#ebebeb 1px solid',
    color: 'rgba(0, 0, 0, 0.87)',
    '&:hover': hoverTableStyles(theme),
    '& $tableItemIcon': {
      color: '#969696',
    },
    '& .item-no-message': {
      whiteSpace: 'break-spaces',
      overflow: 'visible',
    },
  },
  item: {
    minHeight: 46,
    height: 'auto',
    borderBottom: '#ebebeb 1px solid',
    color: 'rgba(0, 0, 0, 0.87)',
    '&:hover': hoverTableStyles(theme),
  },
  tableItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minWidth: '100%',
    minHeight: 46,
    color: '#999',
  },
  goButton: {
    color: 'transparent',
    '&.MuiIconButton-root': {
      position: 'sticky',
      right: 8,
      padding: 6,
    },
    margin: '10px',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  collapse: {
    width: '100%',
  },
  headerCollapse: {
    cursor: 'default',
    display: 'inline-grid',
    flexDirection: 'column',
    alignSelf: 'center',
    color: 'rgba(0, 0, 0, 0.8)',
    fontSize: '1.3rem',
    fontWeight: 450,
    lineHeight: 1.33,
    margin: '10px 10px',
    letterSpacing: '0.01071em',
  },
  orderIcon: {
    marginTop: -4,
    marginLeft: 5,
  },
}));
