import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box, Chip, Grid, Paper, Typography } from '@material-ui/core';
import { ContactMailTwoTone, HowToRegTwoTone } from '@material-ui/icons';
import { useQuery } from '@apollo/react-hooks';
import { GreyLabel } from '../Common/styled/GreyLabel';
import { ByUser } from '../Common/User';
import { getFormattedDate, getYears } from '../../utils/dateTimeUtils';
import { getGenderLabel } from '../../utils/patientGenderList';
import defaultprofilePhoto from '../../images/patient-profile.png';
import InfoControlledAccordion from '../../componentsUI/InfoControlledAccordion';
import { Span } from '../Common/styled/Text';
import { FIND_USER_PROFILE_BY_UUID } from '../../graphql/queries';
import { IconTooltipUI } from '../../componentsUI/IconTooltip';
import { IconButtonUI } from '../../componentsUI/IconButton';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '.75em 0',
  },
  box: {
    width: '100%',
    flexGrow: 1,
    '& .MuiGrid-item': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& > .MuiGrid-container:nth-child(1)': {
      position: 'relative',
      '& > .register': {
        position: 'absolute',
        right: 24,
        bottom: -6,
        color: theme.palette.primary.light,
      },
      '& > .invite': {
        position: 'absolute',
        right: 18,
        bottom: -12,
      },
    },
  },
  mainTitle: {
    margin: '0 1em',
    width: 'calc(100% - 2em)',
    color: theme.palette.primary.main,
    '& > .h1': {
      marginTop: '.5em',
      fontSize: '2em',
      fontWeight: 800,
    },
    '& > .h3': {
      margin: '-.5em 0 .5em',
      fontSize: '1.25em',
      fontWeight: 600,
    },
    '@media (max-width:599px)': {
      margin: '-.5em 0 .5em',
      width: '100%',
      justifyContent: 'center',
      '& h1': {
        margin: '.25em',
      },
    },
  },
  mainInfo: {
    margin: '0 1em .5em',
    width: 'calc(100% - 2em)',
    '@media (max-width:599px)': {
      margin: '0 1.75em',
      width: 'calc(100% - 3.5em)',
    },
  },
  avatarWrapper: {
    margin: '0 0 0 18px',
    '@media (max-width:599px)': {
      margin: '0',
      width: '100%',
      alignItems: 'center',
    },
  },
  avatar: {
    width: '5.5em',
    height: '5.5em',
    maxWidth: '100%',
    maxHeight: '100%',
    marginTop: '1em',
    border: '1px #ddd solid',
  },
  initials: {
    width: '92px',
    height: '92px',
    marginTop: '.625em',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    fontSize: '2.75em',
  },
  keys: {
    color: '#8C8C8C',
    fontSize: '.875em',
  },
  attribute: {
    marginBottom: '.5em',
    fontSize: '.9375em',
  },
  accordion: {
    marginTop: '1em',
    width: '100%',
    '& .MuiAccordionSummary-root': {
      background: theme.palette.primary.dark,
    },
  },
  age: {
    '& .MuiChip-root': {
      fontWeight: 700,
      marginTop: 2,
      marginRight: 6,
      borderRadius: 4,
    },
    '& .MuiChip-colorPrimary': {
      fontWeight: 500,
    },
  },
}));

export default ({ patient, action }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const { data } = useQuery(
    FIND_USER_PROFILE_BY_UUID,
    {
      variables: {
        uuid: patient.userUuid,
      },
    },
  );

  const profilePhoto = data && data.userByUuid && data.userByUuid.profilePhoto;
  const birthDateTime = patient.birthDate && moment(patient.birthDate).utc();
  const initials = patient.name.charAt(0) + patient.surname.charAt(0);

  const getNationality = (item) => item && `${item.toUpperCase()} (${t(item)})`;

  const patientAttributes = [
    { id: 'email', label: 'email' },
    { id: 'identityNumber', label: 'identity.number' },
    { id: 'address', label: 'address', optional: true },
    { id: 'phone', label: 'phone', optional: true },
    { id: 'nationality', label: 'nationality', method: getNationality },
    { id: 'insurance', label: 'insurance', optional: true },
    { id: 'insuranceNumber', label: 'insurance.number', optional: true },
  ];

  const handleChange = (panel) => (event, isExpanded) => setExpanded(isExpanded ? panel : false);

  const PatientAdminItem = () => (
    patientAttributes && patientAttributes.filter((attr) => !!patient[attr.id]).length === 0 ? (
      <Grid container className="user-info-item">
        <Grid item xs={12} className={classes.keys}>
          {t('patient.has.no.personal.data')}
        </Grid>
      </Grid>
    ) : (
      <Grid container direction="column">
        {patientAttributes.map((attr) => (
          (patient[attr.id] || !attr.optional) && (
          <Grid key={attr.id} item xs={12} className="info-item">
            <Grid item className={classes.keys}>
              {t(attr.label)}
            </Grid>
            <Grid item className={classes.attribute}>
              {!attr.method ? patient[attr.id] : attr.method(patient[attr.id])}
            </Grid>
          </Grid>
          )))}
      </Grid>
    )
  );

  const PatientProfileItem = () => (
    <>
      <Grid container className="user-info-item">
        <Grid item xs={12} className={classes.attribute}>
          <Span>
            <GreyLabel>{`${t('patient.file.created.by')}: `}</GreyLabel>
            {patient.createdByData ? <ByUser user={patient.createdByData} /> : <ByUser user={patient.createdBy} />}
          </Span>
        </Grid>
      </Grid>
      <Grid container className="user-info-item">
        <Grid item xs={12} className={classes.attribute}>
          <Span>
            {patient.userUuid ? (
              <GreyLabel>{t('patient.has.access.to.ahp')}</GreyLabel>
            ) : (
              <GreyLabel>{t('patient.not.registered.in.ahp')}</GreyLabel>
            )}
          </Span>
        </Grid>
      </Grid>
      <Grid container className="user-info-item">
        <Grid item xs={12} className={classes.attribute}>
          {patient.invitedBy ? (
            <Span>
              <GreyLabel>{`${t('patient.invited.by')}: `}</GreyLabel>
              <ByUser user={patient.invitedBy} />
            </Span>
          ) : (
            <Span><GreyLabel>{t('patient.not.invited.to.join.ahp')}</GreyLabel></Span>
          )}
        </Grid>
      </Grid>
    </>
  );

  return (
    <Grid item xs={12} md={8} lg={7}>
      <Paper elevation={2} className={classes.wrapper}>
        <Box className={classes.box}>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Grid container justifycontent="center" alignItems="center">
                <Grid item className={classes.avatarWrapper}>
                  {profilePhoto ? (
                    <Avatar
                      alt="Patient"
                      className={classes.avatar}
                      src={profilePhoto || defaultprofilePhoto}
                    />
                  ) : (
                    <Avatar className={classes.initials}>{initials.toUpperCase()}</Avatar>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={9}>

              <Grid container item className={classes.mainTitle}>
                <Typography className="h1">
                  {`${patient.name} ${patient.surname}`}
                </Typography>
                <Typography className="h3">
                  {patient.customId}
                </Typography>
              </Grid>

              <Grid container direction="column" className={classes.mainInfo}>
                <Grid item className={classes.keys}>
                  {`${t('birth.date')}:`}
                </Grid>
                <Grid item className={classes.attribute}>
                  <span className={classes.age}>
                    <Chip
                      size="small"
                      label={getFormattedDate({ date: patient.birthDate })}
                      color="primary"
                    />
                    <Chip
                      size="small"
                      label={`${getYears(birthDateTime)} ${t('years')}`}
                    />
                  </span>
                </Grid>
              </Grid>

              <Grid container direction="column" className={classes.mainInfo}>
                <Grid item className={classes.keys}>
                  {`${t('gender')}:`}
                </Grid>
                <Grid item className={classes.attribute}>
                  {t(getGenderLabel(patient.gender))}
                </Grid>
              </Grid>

            </Grid>
            {patient.userUuid
              ? (
                <Box className="register">
                  <IconTooltipUI
                    Icon={HowToRegTwoTone}
                    title={t('registered.platform')}
                    size={40}
                    placement="left"
                  />
                </Box>
              ) : (
                <Box className="invite">
                  <IconButtonUI
                    Icon={ContactMailTwoTone}
                    title={t('invite.patient')}
                    size={40}
                    placement="top"
                    handleClick={action}
                  />
                </Box>
              )}
          </Grid>

          <Grid container spacing={1}>
            <Grid item className={classes.accordion}>
              <InfoControlledAccordion
                title={t('personal.data')}
                Item={PatientAdminItem}
                expanded={expanded === 'personal-data'}
                onChange={handleChange('personal-data')}
              />
              <InfoControlledAccordion
                title={t('user.info')}
                Item={PatientProfileItem}
                expanded={expanded === 'user-info'}
                onChange={handleChange('user-info')}
              />
            </Grid>
          </Grid>

        </Box>
      </Paper>
    </Grid>
  );
};
