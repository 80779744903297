import React, { forwardRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';
import Loading from '../../Common/Loading';

import { Column } from '../../Common/styled/Groups';
import { GreyLabel } from '../../Common/styled/GreyLabel';
import { ExtraBold, Span } from '../../Common/styled/Text';
import { Margin } from '../../Common/styled/Margins';
import { GET_PATIENT_RELATED_DATA, GET_RECENT_MEDICAL_CASES } from '../../../graphql/queries';
import { DELETE_MEDICAL_CASE } from '../../../graphql/mutations';
import { networkErrorParse } from '../../../utils/ErrorGraphQLUtils';
import { AlertUI } from '../../../componentsUI/Alert';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: 600,
      padding: 15,
    },
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
  },
}));

export const DeleteCaseDialog = ({ open, onClose, medicalCase = {} }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { uuid: medicalCaseUuid } = medicalCase;
  const patient = medicalCase.patient && `${medicalCase.patient.name} ${medicalCase.patient.surname}`;
  const hospitalUuid = useSelector((state) => state.hospital.uuid);
  const [serverError, setServerError] = useState(false);

  const [deleteMedicalCase, { loading, called }] = useMutation(DELETE_MEDICAL_CASE, {
    onCompleted() {
      onClose();
      toast(t('case.removed'), { className: 'toast-info' });
      setTimeout(() => navigate('/cases'), 5000);
    },
    onError(e) {
      setServerError(networkErrorParse(e));
    },
    refetchQueries: [{
      query: GET_RECENT_MEDICAL_CASES,
      variables: {
        hospitalUuid,
        first: 5,
        orderBy: {
          field: 'CREATED_AT',
          direction: 'DESC',
        },
      },
    }, {
      query: GET_PATIENT_RELATED_DATA,
      variables: {
        uuid: patient && patient.uuid,
      },
    }],
  });

  const handleDeleteCase = () => {
    setServerError(false);
    deleteMedicalCase({ variables: { medicalCaseUuid } }).then();
  };

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>{t('case.confirm.delete')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('case.delete.confirmation.message')}
        </DialogContentText>
        <Margin mx-0 mt-0>
          <Column>
            <Span>
              <GreyLabel>{`${t('case.title')}: `}</GreyLabel>
              <ExtraBold>{`${medicalCase.title}`}</ExtraBold>
            </Span>
            <Span>
              <GreyLabel>{`${t('belonging.to.patient')}: `}</GreyLabel>
              <ExtraBold>{patient}</ExtraBold>
            </Span>
          </Column>
        </Margin>
        {called && loading && <Loading />}
        {serverError && <AlertUI severity="error" title="Error">{t(serverError)}</AlertUI>}

      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className={classes.button}
          color="primary"
          onClick={onClose}
          disabled={loading}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          onClick={handleDeleteCase}
          disabled={loading}
        >
          {t('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
