import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Paper } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';

import { Strong } from '../styled/Text';
import { DATE_FORMAT, GetFormat } from '../../../utils/functions';
import { IconTooltipUI } from '../../../componentsUI/IconTooltip';
import { useGridStyles } from '../../Studies/styled/StudiesViewMakeStyles';

export const SelectedItemComponent = ({ item, sentinel, handleClick, dark, label = 'item' }) => {
  const { t } = useTranslation();
  const { fixSidebar } = useSelector((state) => state && state.userInterface);
  const classes = useGridStyles({
    offsetX: fixSidebar ? 285 : 0,
    offsetY: sentinel && sentinel.getBoundingClientRect().y,
    dark,
  });

  return (
    <Box className={classes.selected}>
      <Box>
        <Strong>{`1 ${t(label)} ${t('selected')}`}</Strong>
        :
      </Box>
      <Box>{item.title}</Box>
      <Box>{item.modality}</Box>
      <Box className="patient">{item.patientName}</Box>
      <Box>{GetFormat(item.createdAt, DATE_FORMAT)}</Box>
      <Box>
        <IconTooltipUI
          Icon={HighlightOff}
          title={t('unselect')}
          handleClick={handleClick}
          size="2rem"
        />
      </Box>
    </Box>
  );
};

export const SelectedMultipleItems = ({ length, label = 'item', onClick, ActionIcon = null }) => {
  const { t } = useTranslation();

  const pluralSuffix = length !== 1 ? '.s' : '';
  const itemLabel = `${label}${pluralSuffix}`;
  const selectedLabel = `selected${pluralSuffix}`;

  return (
    <Paper elevation={1}>
      <Box>
        <Strong>{`${length} ${t(itemLabel)} ${t(selectedLabel)}`}</Strong>
      </Box>
      <Box>
        {length && ActionIcon && <ActionIcon />}
        <HighlightOff onClick={onClick} />
      </Box>
    </Paper>
  );
};
