import React from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { getLocaleDateFormat } from '../utils/LocaleUtils';

const useStyles = makeStyles((theme) => ({
  helperTextRoot: {
    color: theme.palette.error.main,
  },
}));

export const DateTimeFieldUI = ({
  name,
  label,
  props,
  className,
  weekday,
  minDate,
  maxDate,
  iconPosition,
  future = false,
  readOnlyInput = false,
  handleChange = () => {},
}) => {
  const { values, errors, setFieldValue } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const dateFormat = getLocaleDateFormat(weekday);
  const pickIconPosition = ['start', 'left'].includes(iconPosition) ? 'start' : 'end';

  const onChange = (date) => {
    setFieldValue(name, (date !== null) ? date : undefined);
    handleChange(date);
  };

  const invalidDateHelperText = `${t('invalid.date')} [${dateFormat.placeholder.replace(/\s+/g, '')}]`;
  const errorDescription = values && errors && values[name] && errors[name]
    && (values[name].toString() === 'Invalid Date' ? invalidDateHelperText : errors[name]);

  return (
    <KeyboardDatePicker
      autoOk
      disableFuture={!future}
      minDate={minDate}
      maxDate={maxDate}
      id={name}
      name={name}
      className={className}
      ampm={false}
      format={dateFormat.field}
      label={label}
      value={values[name] || null}
      onChange={onChange}
      error={false}
      helperText={errorDescription}
      FormHelperTextProps={{
        classes: {
          root: classes && classes.helperTextRoot,
        },
      }}
      InputAdornmentProps={{
        position: pickIconPosition,
      }}
      InputProps={{
        readOnly: readOnlyInput,
      }}
    />
  );
};

export default DateTimeFieldUI;
