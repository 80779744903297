import styled from 'styled-components';
import { SlimCard } from '../../styled/Card';

export const ShareCardsWrapper = styled.div`
  max-height: 50vh;
  overflow: auto;
`;

export const StyledShareCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${SlimCard} {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    border: 1px solid ${(props) => props.theme.colors.gray400}70;
    border-bottom: none;
    box-shadow: 0 1px 1px #00000020;
    box-sizing: border-box;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
`;

export const UserDetails = styled.div`
  vertical-align: middle;
  white-space: nowrap;
  margin-left: 15px;
`;
