import React, { useState } from 'react';

import { Box, FormGroup, FormControlLabel, Switch, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import { GetValueToPath } from '../utils/functions';
import { capitalize } from '../utils/StringUtils';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '.875em',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  onFocusLabel: {
    fontSize: '.875em',
    color: theme.palette.primary.main,
  },
}));

export const SwitcherUI = (
  {
    name,
    label,
    options,
    props,
    disabled = false,
  },
) => {
  const { values } = props;
  const { t } = useTranslation();
  const [focused, setFocused] = useState(false);
  const styles = useStyles(focused);
  const value = GetValueToPath(values, name);
  const opts = options && options.length ? options : [
    { id: 1, value: 'false', label: (t('not')) },
    { id: 2, value: 'true', label: (t('yes')) },
  ];
  const option = opts.find((opt) => opt.value === value.toString());
  const onChange = (a, b) => props.setFieldValue(name, b.toString());
  const focus = () => setFocused(true);
  const blur = () => setFocused(false);
  const style = focused ? 'onFocusLabel' : 'label';

  const SwitchUI = () => (
    <Switch
      checked={value === 'true' || value === true}
      onChange={onChange}
      name={name}
      color="primary"
      disabled={disabled}
    />
  );

  return (
    <Box onMouseEnter={focus} onMouseLeave={blur}>
      <Typography className={styles[style]}>{label}</Typography>
      <FormGroup row>
        <FormControlLabel
          control={<SwitchUI />}
          label={option && option.label}
        />
      </FormGroup>
    </Box>
  );
};
