export const medicalSpecialtyList = [
  { id: 0, value: '', label: 'select.specialty' },
  { id: 1, value: 'ALLERGOLOGY', label: 'allergology' },
  { id: 2, value: 'PATHOLOGICAL_ANATOMY', label: 'pathological_anatomy' },
  { id: 3, value: 'ANESTHESIOLOGY_AND_RESUSCITATION', label: 'anesthesiology_and_resuscitation' },
  { id: 4, value: 'ANGIOLOGY_AND_VASCULAR_SURGERY', label: 'angiology_and_vascular_surgery' },
  { id: 5, value: 'DIGESTIVE_SYSTEM', label: 'digestive_system' },
  { id: 6, value: 'CARDIOLOGY', label: 'cardiology' },
  { id: 7, value: 'CARDIOVASCULAR_SURGERY', label: 'cardiovascular_surgery' },
  { id: 8, value: 'GENERAL_AND_DIGESTIVE_SURGERY', label: 'general_and_digestive_surgery' },
  { id: 9, value: 'ORAL_AND_MAXILLOFACIAL_SURGERY', label: 'oral_and_maxillofacial_surgery' },
  { id: 10, value: 'ORTHOPEDIC_SURGERY_AND_TRAUMATOLOGY', label: 'orthopedic_surgery_and_traumatology' },
  { id: 11, value: 'PEDIATRIC_SURGERY', label: 'pediatric_surgery' },
  { id: 12, value: 'PLASTIC_SURGERY', label: 'plastic_surgery' },
  { id: 13, value: 'AESTHETIC_AND_REPAIRING', label: 'aesthetic_and_repairing' },
  { id: 14, value: 'THORACIC_SURGERY', label: 'thoracic_surgery' },
  { id: 15, value: 'MEDICAL_SURGICAL_DERMATOLOGY_AND_VENEREOLOGY', label: 'medical_surgical_dermatology_and_venereology' },
  { id: 16, value: 'ENDOCRINOLOGY_AND_NUTRITION', label: 'endocrinology_and_nutrition' },
  { id: 17, value: 'CLINICAL_PHARMACOLOGY', label: 'clinical_pharmacology' },
  { id: 18, value: 'GERIATRICS', label: 'geriatrics' },
  { id: 19, value: 'HEMATOLOGY_AND_HEMOTHERAPY', label: 'hematology_and_hemotherapy' },
  { id: 20, value: 'IMMUNOLOGY', label: 'immunology' },
  { id: 21, value: 'WORK_MEDICINE', label: 'work_medicine' },
  { id: 22, value: 'FAMILY_AND_COMMUNITY_MEDICINE', label: 'family_and_community_medicine' },
  { id: 23, value: 'PHYSICAL_MEDICINE_AND_REHABILITATION', label: 'physical_medicine_and_rehabilitation' },
  { id: 24, value: 'INTENSIVE_MEDICINE', label: 'intensive_medicine' },
  { id: 25, value: 'INTERNAL_MEDICINE', label: 'internal_medicine' },
  { id: 26, value: 'NUCLEAR_MEDICINE', label: 'nuclear_medicine' },
  { id: 27, value: 'PREVENTIVE_MEDICINE_AND_PUBLIC_HEALTH', label: 'preventive_medicine_and_public_health' },
  { id: 28, value: 'NEPHROLOGY', label: 'nephrology' },
  { id: 29, value: 'PNEUMOLOGY', label: 'pneumology' },
  { id: 30, value: 'NEUROSURGERY', label: 'neurosurgery' },
  { id: 31, value: 'CLINICAL_NEUROPHYSIOLOGY', label: 'clinical_neurophysiology' },
  { id: 32, value: 'NEUROLOGY', label: 'neurology' },
  { id: 33, value: 'OBSTETRICS_AND_GYNECOLOGY', label: 'obstetrics_and_gynecology' },
  { id: 34, value: 'OPHTHALMOLOGY', label: 'ophthalmology' },
  { id: 35, value: 'MEDICAL_ONCOLOGY', label: 'medical_oncology' },
  { id: 36, value: 'RADIATION_ONCOLOGY', label: 'radiation_oncology' },
  { id: 37, value: 'OTORHINOLARYNGOLOGY', label: 'otorhinolaryngology' },
  { id: 38, value: 'PEDIATRICS', label: 'pediatrics' },
  { id: 39, value: 'PSYCHIATRY', label: 'psychiatry' },
  { id: 40, value: 'RADIODIAGNOSIS', label: 'radiodiagnosis' },
  { id: 41, value: 'RHEUMATOLOGY', label: 'rheumatology' },
  { id: 42, value: 'UROLOGY', label: 'urology' },
];
