import React, { useEffect, useState } from 'react';

import { Loader } from '../Common/Loader/Loader';

export default ({ fetchMoreFn, parent, container = false, threshold = 100, type }) => {
  let containerElement;
  const [sentinel, setSentinel] = useState();
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const calculateHeightDiff = () => {
    if (container) {
      return (
        sentinel.getBoundingClientRect().bottom
        - (containerElement.getBoundingClientRect().y
        + containerElement.getBoundingClientRect().height) < threshold
      );
    }
    return sentinel.getBoundingClientRect().bottom - window.innerHeight < threshold;
  };

  const checkScroll = () => {
    if (
      !isFetchingMore
      && sentinel
      && calculateHeightDiff()
    ) {
      setIsFetchingMore(true);
    }
  };

  useEffect(() => {
    if (container) {
      containerElement = parent.current;
      containerElement.addEventListener('scroll', checkScroll);
      return () => containerElement.removeEventListener('scroll', checkScroll);
    }
    window.addEventListener('scroll', checkScroll);
    return () => window.removeEventListener('scroll', checkScroll);
  });

  useEffect(() => {
    if (isFetchingMore) {
      fetchMoreFn(() => setIsFetchingMore(false), type);
    }
  }, [isFetchingMore]);

  return (
    <Loader isLoading={isFetchingMore}>
      <div id="sentinel" ref={(i) => setSentinel(i)} />
    </Loader>
  );
};
