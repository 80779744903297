import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles((theme) => ({
  icon: {
    '&.selected .MuiSvgIcon-root': {
      fill: theme.palette.primary.dark,
    },
    '&:hover .MuiSvgIcon-root': {
      fill: theme.palette.primary.main,
    },
  },
}));

export const IconTooltipUI = ({
  title = '',
  handleClick,
  disabled,
  Icon = ErrorIcon,
  size,
  placement = 'bottom',
}) => {
  const classes = useStyles();
  const [tooltipActive, setTooltipActive] = useState(false);

  const iconCustomStyle = { fontSize: size || 'inherit' };

  const handleShowTooltip = () => { if (!disabled) setTooltipActive(true); };
  const handleHideTooltip = () => setTooltipActive(false);

  return (
    <Tooltip
      arrow
      title={title}
      TransitionComponent={Zoom}
      placement={placement}
      open={tooltipActive && !disabled}
      onOpen={handleShowTooltip}
      onClose={handleHideTooltip}
    >
      <Icon
        className={classes.icon}
        fontSize="medium"
        style={iconCustomStyle}
        onClick={handleClick}
      />
    </Tooltip>
  );
};

export default IconTooltipUI;
