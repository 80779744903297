import React from 'react';

import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { SlimCard } from '../styled/Card';
import { CollapsedCardWrapper } from '../styled/CollapsedCardWrapper';
import { NoStudiesWrapper } from './styled/NoStudiesWrapper';
import StudyCardDetails from './StudyCardDetails';

const useStyles = makeStyles(() => ({
  box: {
    width: 'calc(100% - 1.5em)',
    margin: '.5em auto 0',
    cursor: 'pointer',
  },
}));

export const SlimStudyCard = ({ medicalCaseUuid, study }) => (
  <SlimCard cursor="pointer">
    <StudyCardDetails medicalCaseUuid={medicalCaseUuid} study={study} />
  </SlimCard>
);

export const ListStudyCard = ({ medicalCaseUuid, study }) => {
  const styles = useStyles();
  return (
    <Box className={styles.box}>
      <StudyCardDetails medicalCaseUuid={medicalCaseUuid} study={study} />
    </Box>
  );
};

export const StudyCard = ({ medicalCaseUuid, study }) => (
  <CollapsedCardWrapper>
    <StudyCardDetails medicalCaseUuid={medicalCaseUuid} study={study} />
  </CollapsedCardWrapper>
);

export const NoStudies = () => {
  const { t } = useTranslation();

  return (
    <NoStudiesWrapper>
      <span>{t('case.no.studies')}</span>
    </NoStudiesWrapper>
  );
};
