import styled from 'styled-components';

export const ShareIcons = styled.div`
  margin-top: 10px;
  display: flex;
  flex: 1 1 0%;
  flex-flow: row wrap;

  a {
    margin: auto 0;
  }
`;

export const ShareIcon = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  position: relative;
  background-image: url(${(props) => props.src});
  background-size: contain;
  background-position: no-repeat;
  margin-right: .25em;

  div {
    display: none;
    position: absolute;
    top: 105%;
    background-color: ${(props) => props.theme.colors.black};
    color: ${(props) => props.theme.colors.white};
    padding: 10% 15%;
    white-space: nowrap;
  }

  :hover {
    div {
      display: block;
      z-index: 10;
    }
  }
`;

export const AddShareWrapper = styled.div`
  display: flex;
  align-items: center;
`;
