import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialogContent-root': {
      '&::-webkit-scrollbar': {
        width: 3,
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.primary.light,
      },
    },
    '& .MuiDialog-paper': {
      padding: 15,
      width: '100%',
      maxWidth: 800,
      height: '100%',
      maxHeight: 'calc(100% - 80px)',
      [theme.breakpoints.down('sm')]: {
        margin: 16,
        padding: 10,
        maxHeight: 'calc(100% - 60px)',
        '& .MuiDialogContent-root': {
          padding: 10,
        },
      },
      [theme.breakpoints.down('xs')]: {
        margin: 8,
        padding: 5,
        maxHeight: 'calc(100% - 40px)',
        '& .MuiDialogContent-root': {
          padding: '5px 0',
        },
      },
    },
    '& .MuiTypography-h6': {
      fontWeight: 600,
    },
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 600,
  },
}));

export default useStyles;
