import gql from 'graphql-tag';

export const GET_VIRTUAL_VISITS = gql`
  query ($patientUuid: ID, $doctorUuid: ID, $hospitalUuid: ID, $first: Int) {
    virtualVisits(patientUuid: $patientUuid, doctorUuid: $doctorUuid, hospitalUuid: $hospitalUuid, first: $first) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ... on VirtualVisit {
            uuid
            patient {
              uuid
              customId
              name
              surname
              birthDate
              createdBy
              createdAt
              updatedAt
            }
            doctorUuid
            medicalCase {
              uuid
              title
            }
            protocol {
              uuid
              name
              minDaysToVisit
              questions {
                uuid
                type
                question
                sequence
                helpText
                helpPictures {
                  uuid
                  picture
                  createdAt
                  updatedAt
                }
                options {
                  uuid
                  optionText
                  score
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            appointment
            medicalImages {
              uuid
              image
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            answered
            protocolQuestionAnswers {
              uuid
              protocolQuestion {
                uuid
                type
                question
                sequence
                helpText
                helpPictures {
                  uuid
                  picture
                  createdAt
                  updatedAt
                }
                options {
                  uuid
                  optionText
                  score
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              protocolQuestionOption {
                uuid
                optionText
                score
                createdAt
                updatedAt
              }
              answer
              protocolQuestionAnswerImages {
                uuid
                image
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            comments {
              uuid
              author {
                uuid
                name
                surname
                profilePhoto
              }
              createdAt
              body
            }
          }
        }
      }
    }
  }
`;

export const GET_PATIENT_VIRTUAL_VISITS = gql`
  query ($patientUuid: ID, $doctorUuid: ID, $first: Int) {
    virtualVisits(patientUuid: $patientUuid, doctorUuid: $doctorUuid, first: $first) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ... on VirtualVisit {
            uuid
            patient {
              uuid
              customId
              name
              surname
              birthDate
              createdBy
              createdAt
              updatedAt
            }
            medicalCase {
              uuid
              title
            }
            doctor {
              uuid
              name
              surname
              fullname
            }
            protocol {
              uuid
              name
              minDaysToVisit
              questions {
                uuid
                type
                question
                sequence
                helpText
                helpPictures {
                  uuid
                  picture
                  createdAt
                  updatedAt
                }
                options {
                  uuid
                  optionText
                  score
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            appointment
            medicalImages {
              uuid
              image
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            answered
            protocolQuestionAnswers {
              uuid
              protocolQuestion {
                uuid
                type
                question
                sequence
                helpText
                helpPictures {
                  uuid
                  picture
                  createdAt
                  updatedAt
                }
                options {
                  uuid
                  optionText
                  score
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              protocolQuestionOption {
                uuid
                optionText
                score
                createdAt
                updatedAt
              }
              answer
              protocolQuestionAnswerImages {
                uuid
                image
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;

export const GET_VIRTUAL_VISIT = gql`
  query ($uuid: ID!) {
    virtualVisit(uuid: $uuid) {
      uuid
      medicalCase{
        title
        uuid
      }
      patient {
        uuid
        customId
        name
        surname
        birthDate
        createdBy
        createdAt
        updatedAt
        hospital {
          uuid
        }
      }
      doctor {
        uuid
        name
        surname
      }
      doctorUuid
      protocol {
        uuid
        name
        state
        hospitalUuid
        minDaysToVisit
        questions {
          uuid
          type
          question
          sequence
          helpText
          helpPictures {
            uuid
            picture
            createdAt
            updatedAt
          }
          options {
            uuid
            optionText
            score
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      appointment
      medicalImages {
        uuid
        image
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      answered
      protocolQuestionAnswers {
        uuid
        protocolQuestion {
          uuid
          type
          question
          sequence
          helpText
          helpPictures {
            uuid
            picture
            createdAt
            updatedAt
          }
          options {
            uuid
            optionText
            score
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        protocolQuestionOption {
          uuid
          optionText
          score
          createdAt
          updatedAt
        }
        answer
        protocolQuestionAnswerImages {
          uuid
          image
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      comments {
        uuid
        author {
          uuid
          name
          surname
          profilePhoto
        }
        createdAt
        body
      }
    }
  }
`;

export const CREATE_VIRTUAL_VISIT = gql`
  mutation ($input: CreateVirtualVisitInput!) {
    createVirtualVisit(input: $input) {
      uuid
      patient {
        uuid
        customId
        name
        surname
        birthDate
        createdBy
        createdAt
        updatedAt
      }
      doctorUuid
      protocol {
        uuid
        name
        minDaysToVisit
        questions {
          uuid
          type
          question
          sequence
          helpText
          helpPictures {
            uuid
            picture
            createdAt
            updatedAt
          }
          options {
            uuid
            optionText
            score
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      appointment
      medicalImages {
        uuid
        image
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      answered
      protocolQuestionAnswers {
        uuid
        protocolQuestion {
          uuid
          type
          question
          sequence
          helpText
          helpPictures {
            uuid
            picture
            createdAt
            updatedAt
          }
          options {
            uuid
            optionText
            score
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        protocolQuestionOption {
          uuid
          optionText
          score
          createdAt
          updatedAt
        }
        answer
        protocolQuestionAnswerImages {
          uuid
          image
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      comments {
        uuid
        author {
          uuid
          name
          surname
          profilePhoto
        }
        createdAt
        body
      }
    }
  }
`;

export const UPDATE_VIRTUAL_VISIT = gql`
  mutation ($input: UpdateVirtualVisitInput!) {
    updateVirtualVisit(input: $input) {
      uuid
      patient {
        uuid
        customId
        name
        surname
        birthDate
        createdBy
        createdAt
        updatedAt
      }
      doctorUuid
      protocol {
        uuid
        name
        minDaysToVisit
        questions {
          uuid
          type
          question
          sequence
          helpText
          helpPictures {
            uuid
            picture
            createdAt
            updatedAt
          }
          options {
            uuid
            optionText
            score
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      appointment
      medicalImages {
        uuid
        image
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      answered
      protocolQuestionAnswers {
        uuid
        protocolQuestion {
          uuid
          type
          question
          sequence
          helpText
          helpPictures {
            uuid
            picture
            createdAt
            updatedAt
          }
          options {
            uuid
            optionText
            score
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        protocolQuestionOption {
          uuid
          optionText
          score
          createdAt
          updatedAt
        }
        answer
        protocolQuestionAnswerImages {
          uuid
          image
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      comments {
        uuid
        author {
          uuid
          name
          surname
          profilePhoto
        }
        createdAt
        body
      }
    }
  }
`;

export const ADD_VIRTUAL_VISIT_COMMENT = gql`
  mutation ($input: AddVirtualVisitCommentInput!) {
    addVirtualVisitComment(input: $input) {
      uuid
      createdAt
      updatedAt
      comments {
        uuid
        createdAt
        body
        author {
          uuid
          name
          surname
          profilePhoto
        }
      }
    }
  }
`;

export const CREATE_VIRTUAL_VISIT_ANSWERS = gql`
  mutation ($input: CreateVirtualVisitAnswersInput!) {
    createVirtualVisitAnswers(input: $input) {
      uuid
      patient {
        uuid
        customId
        name
        surname
        birthDate
        createdBy
        createdAt
        updatedAt
      }
      doctor {
        uuid
        name
        surname
      }
      protocol {
        uuid
        name
        minDaysToVisit
        questions {
          uuid
          type
          question
          sequence
          helpText
          helpPictures {
            uuid
            picture
            createdAt
            updatedAt
          }
          options {
            uuid
            optionText
            score
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      appointment
      medicalImages {
        uuid
        image
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      answered
      protocolQuestionAnswers {
        uuid
        protocolQuestion {
          uuid
          type
          question
          sequence
          helpText
          helpPictures {
            uuid
            picture
            createdAt
            updatedAt
          }
          options {
            uuid
            optionText
            score
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        protocolQuestionOption {
          uuid
          optionText
          score
          createdAt
          updatedAt
        }
        answer
        protocolQuestionAnswerImages {
          uuid
          image
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
