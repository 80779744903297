import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import { capitalize } from '@material-ui/core';
import InfoAccordion from '../../../componentsUI/InfoAccordion';
import ArchivesTableCollapse from '../../../componentsUI/ArchivesTableCollapse';
import { GetFormat } from '../../../utils/functions';

const explorations = [
  { id: 'temperature', label: 'body.temperature', unit: 'ºC' },
  { id: 'height', label: 'height', unit: 'cm' },
  { id: 'weight', label: 'weight', unit: 'kg' },
  { id: 'bloodPressureSystolic', label: 'bp.systolic', unit: 'mm[Hg]' },
  { id: 'bloodPressureDiastolic', label: 'bp.diastolic', unit: 'mm[Hg]' },
  { id: 'oxygenSaturation', label: 'o2.saturation', unit: '%' },
  { id: 'pulse', label: 'pulse', unit: '/ min' },
  { id: 'respirations', label: 'respirations', unit: '/ min' },
];

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0 2em 1em',
  },
  datetime: {
    margin: '.25em .375em 0',
    fontSize: '0.75em',
    color: theme.palette.grey.A200,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    color: theme.palette.primary.main,
  },
  measureKey: {
    width: 250,
    fontSize: '.875em',
    color: theme.palette.grey.A400,
    fontWeight: 600,
  },
  measureValue: {
    width: 40,
    textAlign: 'right',
    fontWeight: 700,
  },
  measureUnit: {
    paddingLeft: 4,
    fontWeight: 700,
  },
  noItem: {
    margin: '.25em',
    fontSize: '.875em',
    color: '#909090',
  },
}));

const ExplorationsCollapse = ({ item }) => {
  const laboratory = item.node ? item.node : item;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {explorations.map((field) => !!laboratory[field.id] && (
        <div key={field.id} className={classes.wrapper}>
          <div className={classes.measureKey}>{`${capitalize(t(field.label))}:`}</div>
          <div className={classes.measureValue}>{laboratory[field.id]}</div>
          <div className={classes.measureUnit}>{field.unit}</div>
        </div>
      ))}
    </div>
  );
};

const Explorations = ({ values }) => {
  const [selected, setSelected] = useState([]);
  const { t } = useTranslation();
  const classes = useStyles();

  const getDateTime = (item) => (
    <div className={classes.datetime}>
      {GetFormat(item.createdAt, 'DATEFORMAT')}
    </div>
  );

  const handleGoto = (evt, index) => {
    evt.stopPropagation();
    const indexCase = values[index];

    navigate(`/case/${indexCase.uuid}`);
  };

  const emptyList = !values || !values.length;
  const ExplorationsList = () => {
    if (emptyList) return <div style={{ margin: '.5em 1em' }}>{t('no.explorations')}</div>;

    const sorted = [...values].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    return (
      <ArchivesTableCollapse
        responsive={false}
        fieldNames={null}
        items={sorted}
        GetTitle={() => t('vital.signs')}
        GetSubtitle={getDateTime}
        GetCollapse={ExplorationsCollapse}
        selected={selected}
        setSelected={setSelected}
        handleGoto={handleGoto}
      />
    );
  };

  return (
    <InfoAccordion title={t('explorations')} Item={ExplorationsList} expanded={!emptyList} />
  );
};

export default Explorations;
