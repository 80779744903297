import { makeStyles } from '@material-ui/core/styles';

const baseListStyles = (theme) => ({
  studyInfo: {
    display: 'inline',
    marginLeft: 12,
    color: theme.palette.primary.light,
  },
  studyDate: {
    display: 'inline',
    fontSize: '.9375em',
    fontWeight: 700,
  },
  collapsedInfo: {
    margin: '-4px 0 10px 15px',
    fontSize: '1.25rem',
  },
});

const baseGridStyles = (theme, { offsetX, offsetY, dark = false }) => ({
  section: {
    position: 'relative',
    width: `calc(100vw - ${offsetX}px)`,
    height: `calc(100vh - ${offsetY}px)`,
    ...dark && { color: '#EEE' },
    ...dark && { backgroundColor: '#222' },
    '@media (max-width: 991px)': {
      width: '100vw',
    },
    '@media (max-height: 600px)': {
      height: 'calc(100vh - 129px)',
    },
  },
  selection: {
    position: 'absolute',
    top: 36,
    left: 0,
    zIndex: 20,
    '@media (max-width: 991px)': {
      top: 32,
    },
    '& > div': {
      display: 'flex',
      justifyContent: 'flex-start',
      margin: 0,
      borderRadius: 0,
      padding: '8px 24px',
      color: 'white',
      backgroundColor: `${theme.palette.primary.main}DF`,
      // background: `linear-gradient(90deg, ${theme.palette.primary.main}3F 0%, ${theme.palette.primary.main}E7 2%, ${theme.palette.primary.main}E7 98%, ${theme.palette.primary.main}3F 100%)`,
      borderTop: `1px solid ${theme.palette.primary.light}`,
      borderBottom: `1px solid ${theme.palette.primary.light}`,
      '@media (max-width: 991px)': {
        padding: '8px 18px',
      },
      '@media (max-width: 600px)': {
        padding: '8px 12px',
      },
      '& > .MuiBox-root': {
        paddingTop: 7,
        paddingLeft: 12,
        fontSize: '1.175rem',
        '&:nth-child(1)': {
          paddingTop: 5,
          paddingLeft: 4,
          paddingRight: 4,
          fontSize: '1.4rem',
        },
        '&:.patient': {
          fontWeight: 600,
        },
        '&:last-child': {
          paddingTop: 4,
          alignSelf: 'flex-end',
          marginLeft: 'auto',
          '& > svg.MuiSvgIcon-root': {
            color: '#FFFFFFD7',
            cursor: 'pointer',
            '&:hover': {
              color: '#FFF',
            },
          },
        },
        '@media (max-width: 991px)': {
          display: 'none',
          maxWidth: 250,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          '&:nth-child(-n + 3)': {
            display: 'block',
          },
          '&:last-child': {
            display: 'block',
          },
        },
        '@media (max-width: 600px)': {
          maxWidth: 150,
          '&:nth-child(3)': {
            display: 'none',
          },
        },
      },
    },
  },
  main: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    overflow: 'hidden',
  },
  wrapper: {
    height: 'calc(100% - 10px)',
    paddingTop: 12,
    paddingBottom: 14,
    '@media (max-width: 991px)': {
      height: 'calc(100% - 8px)',
      paddingTop: 8,
      paddingBottom: 8,
    },
    '@media (max-width: 480px)': {
      height: 'calc(100% - 6px)',
      paddingTop: 6,
      paddingBottom: 6,
    },
  },
  box: {
    width: 'calc(100% - 32px)',
    height: 'calc(100% - 32px)',
    minHeight: 'calc(100% - 32px)',
    overflow: 'hidden',
    padding: 16,
    paddingBottom: 0,
    backgroundColor: dark ? '#273541' : '#FCFCFC',
    borderRadius: 10,
  },
  tableContainer: {
    position: 'relative',
    width: 'calc(100% + 32px) !important',
    margin: '0 -16px',
    backgroundColor: dark ? '#77777710' : '#AAAAAA15',
    borderRadius: 0,
    transform: 'rotateX(180deg)',
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      height: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: dark ? '#3F4B56' : '#F0F0F0',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: dark ? '#8F9BA6' : theme.palette.primary.light,
      border: dark ? '1px solid #3F4B56' : '1px solid #F0F0F0',
      borderRadius: '3px',
    },
    '& .loading': {
      height: 55,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  tableWrapper: {
    transform: 'rotateX(180deg)',
    height: `calc(100vh - ${offsetY - 16}px)`,
    width: 'max-content',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '@media (max-height:600px)': {
      height: 'calc(100vh - 193px)',
    },
  },
  table: {
    position: 'relative',
  },
  head: {
    '& > tr > th.MuiTableCell-head': {
      padding: 12,
      backgroundColor: dark ? '#071521' : theme.palette.primary.dark,
      color: '#FFFFFFD5',
      '&:hover': {
        cursor: 'default',
      },
    },
  },
  body: {

  },
  rows: {
    '&:nth-of-type(odd)': {
      backgroundColor: dark ? '#13212D' : `${theme.palette.primary.main}1D`,
    },
    '&:nth-of-type(even)': {
      backgroundColor: dark ? '#1B2935' : `${theme.palette.primary.main}12`,
    },
    '&:hover': {
      backgroundColor: dark ? '#273541' : `${theme.palette.primary.main}37`,
    },
    '&[aria-checked="true"]': {
      backgroundColor: dark ? '#374551' : `${theme.palette.primary.main}47`,
    },
    '&.disabled': {
      backgroundColor: `${theme.palette.grey.A200}30`,
      '& > td, svg.MuiSvgIcon-root': {
        color: 'transparent',
        textShadow: `0 0 3px ${theme.palette.grey.A400}47`,
      },
      '& .MuiTableCell-root': {
        borderBottom: `1px solid ${theme.palette.grey.A200}57`,
      },
    },
    '& .MuiTableCell-root': {
      maxWidth: 350,
      height: 19,
      maxHeight: 19,
      padding: 12,
      ...dark && { color: '#FFFFFFD5' },
      borderBottom: dark ? '1px solid #EEE3' : `1px solid ${theme.palette.primary.main}2A`,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      webkitLineClamp: 1,
      lineClamp: 1,
      '& *': {
        display: 'inline',
        margin: 0,
      },
      '& br': {
        display: 'none',
      },
      '&:hover': {
        cursor: 'default',
      },
      '&.actions': {
        padding: '4px 0 0 10px',
        textAlign: 'center',
        verticalAlign: 'middle',
      },
      '&.cursor:hover': {
        cursor: 'pointer',
      },
      '& svg.MuiSvgIcon-root': {
        fontSize: '2rem',
        height: '1.875rem',
        color: dark ? '#AFBBC6' : theme.palette.primary.main,
      },
    },
  },
  last: {
    height: 44,
    padding: 0,
    '& .MuiTableCell-root': {
      padding: 0,
      borderBottom: '0px',
    },
  },
  pagination: {
    position: 'absolute',
    top: 'calc(100% - 75px)',
    left: 0,
    '& .MuiTablePagination-root > .MuiTablePagination-toolbar': {
      background: '#FFFD',
      borderBottom: dark ? '1px solid #EEE3' : `1px solid ${theme.palette.primary.main}2A`,
      '@media (max-width: 450px)': {
        overflowX: 'scroll',
        '&::-webkit-scrollbar': {
          height: '8px',
        },
        '&::-webkit-scrollbar-track': {
          // background: dark ? '#3F4B56' : '#F0F0F0',
        },
        '&::-webkit-scrollbar-thumb': {
          // backgroundColor: dark ? '#8F9BA6' : theme.palette.primary.light,
          border: dark ? '1px solid #3F4B56' : '1px solid #F0F0F0',
          borderRadius: '3px',
        },
      },
    },
    '& .MuiTablePagination-caption': {
      marginLeft: 25,
    },
    '@media (max-width: 600px)': {
      background: '#FFF',
    },
  },
  listAction: {
    height: 45,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    border: '1px solid',
    borderLeft: 'none',
    borderRight: 'none',
    borderColor: `${theme.palette.grey.A400}20`,
    background: theme.palette.background.default,
    '& .MuiButton-root': {
      margin: '2px 16px',
      lineHeight: 1.55,
      borderRadius: 0,
    },
  },
  actionIconButton: {
    marginRight: 20,
  },
});

const baseFilterStyles = (theme, { dark = false }) => ({
  box: {
    marginTop: 16,
    marginBottom: 4,
    padding: '12px 18px',
    backgroundColor: '#FBFBFB',
    '@media (max-width: 599)': {
      margin: 16,
      marginBottom: 2,
      padding: '8px 12px',
    },
  },
  form: {
    '& .MuiTextField-root': {
      width: '100%',
      color: dark ? '#DFEBF6' : theme.palette.primary.light,
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: dark ? '#3F4B56' : '#FFFFFF',
      '& fieldset': {
        ...dark && { borderColor: '#5F6B76' },
      },
      '&:hover fieldset': {
        ...dark && { borderColor: '#7F8B96' },
      },
      '&.Mui-focused fieldset': {
        ...dark && { borderColor: '#8F9BA6' },
      },
    },
    '& .MuiInputBase-input': {
      ...dark && { color: '#DFEBF6' },
    },
    '& .MuiInput-underline': {
      '&:before': {
        ...dark && { color: '#DFEBF6' },
        ...dark && { borderBottom: '2px solid #DFEBF66B' },

      },
      '&:hover:before': {
        ...dark && { color: '#DFEBF6' },
        ...dark && { borderBottom: '2px solid #DFEBF685' },
      },
      '&:after': {
        ...dark && { borderBottom: '2px solid #DFEBF69E' },
      },
      '&.Mui-focused:after': {
        ...dark && { borderBottom: '2px solid #DFEBF69E' },
      },
    },
    '& .MuiInputLabel-root': {
      ...dark && { color: '#DFEBF6' },
    },
    '& .MuiIconButton-label': {
      ...dark && { color: '#BFCBD68A' },
    },
    '& .MuiInputLabel-outlined': {
      ...dark && { color: '#BFCBD6' },
      transform: 'translate(12px, 12px) scale(1)',
      '&.MuiInputLabel-shrink': {
        ...dark && { color: '#DFEBF6' },
        transform: 'translate(14px, -6px) scale(0.75)',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px 12px',
    },
    '& .MuiFormHelperText-root': {
      ...dark && { color: '#F76050' },
    },
  },
  modalities: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    minHeight: 30,
    '& .MuiChip-root': {
      margin: '0 8px 6px 0',
      height: 28,
      border: dark ? '1px solid #3F4B56' : '1px solid #B7B7B7',
      borderRadius: 6,
      fontSize: '1.375rem',
      ...dark && { color: '#91B9CD' },
      backgroundColor: dark ? '#222' : '#EAEAEA',
      '&:hover': {
        backgroundColor: dark ? '#2A2A2A' : '#DDD',
      },
      '&.selected': {
        color: dark ? '#000' : '#FFF',
        backgroundColor: dark ? '#91B9CD' : theme.palette.primary.light,
        borderColor: dark ? '#21597D' : theme.palette.primary.main,
        fontWeight: 600,
        '&:hover': {
          backgroundColor: dark ? '#A1C9DD' : theme.palette.primary.main,
        },
      },
      '& > *': {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
  },
});

export const useListStyles = makeStyles((theme) => (
  baseListStyles(theme)
));

export const useGridStyles = ({ offsetX, offsetY, dark }) => makeStyles((theme) => (
  baseGridStyles(theme, { offsetX, offsetY, dark })
))();

export const useFilterStyles = ({ dark }) => makeStyles((theme) => (
  baseFilterStyles(theme, { dark })
))();
