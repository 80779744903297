import { makeStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: '4px 0',
    fontSize: '1.375em',
    fontWeight: 600,
  },
  dialog: {
    '& .MuiDialog-paper': {
      padding: 15,
      width: '100%',
      maxWidth: 800,
    },
    '& .MuiTypography-h6': {
      fontWeight: 600,
    },
  },
  error: {
    padding: 24,
  },
  label: {
    color: alpha(theme.palette.text.primary, 0.54),
  },
  helperTextRoot: {
    color: theme.palette.error.main,
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
  },
  subheader: {
    borderBottom: '1px solid #0002',
  },
  container: {
    maxWidth: '100%',
    padding: '.75em 2.5em',
    '@media (max-width:600px)': {
      padding: '.75em 1.5em',
    },
  },
  wrapper: {
    width: 'calc(100% - 40px)',
    maxWidth: 600,
    margin: '1em auto 1em',
    padding: '.5em 1.5em',
    '@media (max-width:600px)': {
      padding: '.5em 1em',
    },
  },
  form: {
    padding: '12px 0',
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  actions: {
    marginBottom: 8,
  },
}));

export default useStyles;
