import { makeStyles } from '@material-ui/core/styles';

export const useDashboardStyles = makeStyles((theme) => ({
  flex: {
    '& .MuiGrid-root': {
      flexGrow: 1,
      flexBasis: '50%',
      '@media (max-width:599px)': {
        flexBasis: '100%',
      },
    },
  },
  title: {
    margin: '1em .375em -.75em',
    padding: '.25em .625em',
    cursor: 'pointer',
    '& > p': {
      fontSize: '1.25em',
      fontWeight: 600,
      color: theme.palette.primary.main,
    },
  },
}));

export default useDashboardStyles;
