import React from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';

import { Paper, Typography } from '@material-ui/core';
import { InfoOutlined, InfoTwoTone, Web } from '@material-ui/icons';

import { isEmpty, sortGraphQueryList } from '../../../utils/ObjectUtils';
import Loading from '../../Common/Loading';

import { GET_PATIENT_MEDICAL_CASES } from '../../../graphql/queries';
import TableCollapse from '../../../componentsUI/TableCollapse';
import { DATE_FORMAT } from '../../../utils/functions';
import { networkErrorParse } from '../../../utils/ErrorGraphQLUtils';
import { AlertWrapperUI, GraphQLAlertUI } from '../../../componentsUI/Alert';
import { useDashboardStyles } from '../Styles/DashboardStyles';
import { GetTitle, GetSubTitle } from '../../Studies/StudiesListDetailView';
import {webViewerEndpoints} from "../../../../config";

const fieldNames = [
  { label: 'study.title', id: 'title', field: 'TITLE', direction: 'ASC', width: 370 },
  { label: 'study.date', id: 'createdAt', field: 'CREATED_AT', direction: 'DESC', width: 120, format: DATE_FORMAT },
];

export const PatientStudiesView = ({ selectedStudy, selectStudy }) => {
  const { t } = useTranslation();
  const classes = useDashboardStyles();

  const orderByField = 'CREATED_AT';
  const orderByDirection = 'DESC';

  const user = useSelector((state) => state.userInterface.user);
  const { loading, error, data, refetch } = useQuery(
    GET_PATIENT_MEDICAL_CASES, {
      variables: {
        uuid: user.patientUuid,
        first: 20,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const errorMessage = networkErrorParse(error);

  const medicalCases = data && data.patient && data.patient.medicalCases;

  const dicomStudies = medicalCases && !isEmpty(medicalCases) && sortGraphQueryList(
    medicalCases.map((medicalCase) => medicalCase.studies
      .map((study) => ({
        node: {
          ...study,
          medicalCase: {
            uuid: medicalCase.uuid,
            name: medicalCase.title,
          },
        },
      }))).flat(1),
    fieldNames,
    orderByField,
    orderByDirection,
  ).slice(0, 5);

  const handleViewStudies = (evt) => {
    evt.stopPropagation();

    navigate('/studies');
  };

  const handleGotoStudy = (evt, index) => {
    evt.stopPropagation();

    navigate(`/study/${dicomStudies[index].node.uuid}`);
  };

  const defined = (item) => (item === 0 || item !== null);

  const selected = (dicomStudies && selectedStudy)
    ? dicomStudies.map((item, key) => (item.node.uuid === selectedStudy.uuid ? key : null)).filter(defined)
    : [];

  const handleSelectCase = (sel) => {
    if (!sel) return;
    if (!sel.length) {
      selectStudy(null);
      return;
    }
    selectStudy(selected.length && sel[0] === selected[0].node ? null : dicomStudies[sel[0]].node);
  };

  const isBrowser = () => typeof window !== 'undefined';

  const openInWebViewer = (event, index) => {
    event.preventDefault();
    if (!isBrowser) return;

    const requestedStudy = dicomStudies[index] && dicomStudies[index].node;
    window.open(`${webViewerEndpoints.getStudy}${requestedStudy.studyInstanceUid}`, '_blank');
  };

  if (errorMessage) {
    return (
      <AlertWrapperUI>
        <GraphQLAlertUI severity="warning" title="Error" refetch={refetch}>
          {t(errorMessage)}
        </GraphQLAlertUI>
      </AlertWrapperUI>
    );
  }

  const ActionButtons = [
    { Icon: Web, tooltip: 'open.in.viewer', onClick: openInWebViewer },
    { Icon: InfoOutlined, tooltip: 'see.detail', onClick: handleGotoStudy },
  ];

  return (
    <>
      <Paper elevation={2} className={classes.title} onClick={handleViewStudies}>
        <Typography>
          {t('studies')}
        </Typography>
      </Paper>
      { loading && isEmpty(data) && <Loading /> }
      { dicomStudies
      && (
        <TableCollapse
          responsive={false}
          fieldNames={fieldNames}
          items={dicomStudies}
          GetTitle={GetTitle}
          GetSubtitle={GetSubTitle}
          selected={selected}
          setSelected={handleSelectCase}
          handleGoto={handleGotoStudy}
          ActionButtons={ActionButtons}
        />
      )}
    </>
  );
};
