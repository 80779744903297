/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import IcomoonReact from 'icomoon-react';
import { ThemeContext } from 'styled-components';
import { Link } from 'gatsby';

import { Container } from '@material-ui/core';
import icons from '../../../icons/cloud.json';
import { Card, useCardStyles } from '../styled/Card';
import { KBytesPrettify } from '../../../utils/BytesPrettifyUtils';
import { GreyLabel } from '../styled/GreyLabel';
import { DeleteFileDialog } from '../../File/DeleteFileDialog';

export const BasicFileCardDetails = ({ file, hideDownload }) => {
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();

  function openViewer(images) {
    dispatch({ type: 'SET_VIEWER_IMAGES', viewerImages: { viewerImages: images.map((image) => ({ src: image })) } });
    dispatch({ type: 'TOGGLE_VIEWER', showViewer: { showViewer: true } });
  }

  const imageExtensions = ['jpg', 'gif', 'png', 'jpeg'];
  const documentExtensions = ['pdf', 'sql'];
  // const videoExtensions = ['mp4', '3gpp'];

  function getExtension(urlString) {
    try {
      const url = new URL(urlString);
      return url.pathname.substr(url.pathname.lastIndexOf('.') + 1);
    } catch (error) {
      return null;
    }
  }

  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex align-items-center">
        {imageExtensions.includes(getExtension(file.file)) && (
          <img height="39" className="cursor-pointer mr-4" src={file.file} alt="answer" onClick={(event) => { event.preventDefault(); event.stopPropagation(); openViewer([file.file]); }} />
        )}
        {documentExtensions.includes(getExtension(file.file)) && (
          <IcomoonReact iconSet={icons} className="mr-4" color={themeContext.colors.primary} size={26} icon={`file_${getExtension(file.file).toUpperCase()}`} />
        )}
        {file.fileName}
        {file.fileSize && (
          <GreyLabel>
            &nbsp;
            <small>
              &nbsp;
              {KBytesPrettify(file.fileSize).string}
            </small>
          </GreyLabel>
        )}
      </div>
      {!hideDownload && (
        <div className="mt-2 mb-3">
          <IcomoonReact className="cursor-pointer" iconSet={icons} color={themeContext.colors.primary} size={20} icon="download" />
        </div>
      )}
    </div>
  );
};

export const BasicFileCardDelete = ({ file, type, medicalCase = null }) => {
  const themeContext = useContext(ThemeContext);
  const [showModal, setModal] = useState(false);
  return (
    <div>
      <DeleteFileDialog open={showModal} onClose={() => setModal(false)} file={file} uuid={medicalCase.uuid} type={type} />
      <Card cursor="pointer" className="ml-1" onClick={() => setModal(true)}>
        <div className="d-flex align-items-center">
          <IcomoonReact className="cursor-pointer" iconSet={icons} color={themeContext.colors.primary} size={20} icon="bin" />
        </div>
      </Card>
    </div>
  );
};

export const BasicFileCard = ({ file, type, medicalCase = null }) => (
  <div className="d-flex flex-row justify-content-between w-100">
    <a className="no-link w-100" href={file.file} target="_blank" rel="noopener noreferrer" download={file.fileName}>
      <Card padding=".875em 1.75em" cursor="pointer">
        <BasicFileCardDetails file={file} />
      </Card>
    </a>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <a className="no-link" rel="noopener noreferrer">
      <BasicFileCardDelete file={file} type={type} medicalCase={medicalCase} />
    </a>
  </div>
);

export const BasicFileCardInline = ({ medicalCase, file }) => (
  <div className="py-3">
    <Link className="no-link" to={`/case/${medicalCase.uuid}`}>
      <BasicFileCardDetails file={file} hideDownload />
    </Link>
  </div>
);

export const BasicFileCardList = ({ file }) => (
  <li>
    <p style={{ margin: '0 2em 0 0', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
      {file.fileName}
    </p>
  </li>
);
