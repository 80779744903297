import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { useKeycloak } from 'react-keycloak';
import { useMutation } from '@apollo/react-hooks';
import { Typography } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

import { CommentsWrapper, CommentBox } from '../styled/CaseCommentsStyles';
import { LongButton } from '../../Common/styled/Button';
import { UserCommentItem } from './UserCommentItem';

import { ADD_MEDICAL_CASE_COMMENT } from '../../../graphql/mutations';
import { useApplicationInterface } from '../../../utils/ApplicationInterfaceUtils';

const { appPatient } = useApplicationInterface();

export const CaseComments = ({ medicalCase, refetch, toPatient = false }) => {
  const { t } = useTranslation();
  const language = i18n && i18n.language;
  const [keycloak] = useKeycloak();
  const [commentText, setCommentText] = useState('');
  const [isSendCommentButtonActive, setIsSendCommentButtonActive] = useState(false);
  const [addMedicalCaseCommentMutation] = useMutation(ADD_MEDICAL_CASE_COMMENT, {
    onCompleted() {
      refetch();
    },
  });

  let label = toPatient ? 'patient.comments' : 'medical.comments';
  if (appPatient) label = 'comments';

  const postComment = () => {
    if (!isSendCommentButtonActive) {
      return;
    }

    addMedicalCaseCommentMutation({
      variables: {
        input: {
          medicalCaseUuid: medicalCase.uuid,
          body: commentText,
          patientCommunication: toPatient,
          language,
        },
      },
      optimisticResponse: {
        addMedicalCaseComment: {
          ...medicalCase,
          comments: [...medicalCase.comments, {
            __typename: 'Comment',
            uuid: uuidv4(),
            author: {
              __typename: 'User',
              uuid: uuidv4(),
              name: keycloak.tokenParsed.given_name,
              surname: keycloak.tokenParsed.family_name,
              profilePhoto: 'profilePhoto',
            },
            createdAt: new Date(),
            body: commentText,
          }],
        },
      },
    }).then();
    setCommentText('');
  };

  const onQuillChange = ({ content, editor }) => {
    setCommentText(content);
    setIsSendCommentButtonActive(editor.getText().trim() !== '');
  };

  const onQuillKeyDown = (event) => {
    // Post comment if ctrl + intro
    if (event.keyCode === 13 && event.ctrlKey) {
      postComment();
    }
  };

  const comments = medicalCase.comments.filter((comment) => (
    comment.patientCommunication === toPatient
  ));

  return (
    <CommentsWrapper>
      <Typography variant="h6">{t(label)}</Typography>
      {
        comments.map((comment) => (
          <UserCommentItem comment={comment} key={comment.uuid} fromNow />
        ))
      }
      <CommentBox>
        <ReactQuill
          value={commentText}
          theme="snow"
          bounds=".quill"
          placeholder={t('comment.placeholder')}
          modules={{
            toolbar: [
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['link'],
            ],
            clipboard: {
              matchVisual: false,
            },
          }}
          onChange={(content, delta, source, editor) => { onQuillChange({ content, delta, source, editor }); }}
          onKeyDown={(event) => { onQuillKeyDown(event); }}
        />
        <LongButton onClick={postComment} margin={2} float="right" disabled={!isSendCommentButtonActive}>
          {t('post.comment')}
        </LongButton>
      </CommentBox>
    </CommentsWrapper>
  );
};
