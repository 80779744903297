import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GetValueToPath } from '../utils/functions';

const useStyles = makeStyles((theme) => ({
  helperTextRoot: {
    color: theme.palette.error.main,
  },
  root: {
    '& .MuiInputBase-input': {
      fontFamily: 'Muli',
      color: '#606060',
    },
  },
}));

export const TextAreaUI = ({ type, variant, name, label, props, disabled = false, classes = useStyles() }) => {
  const { values, handleChange, errors } = props;
  const value = GetValueToPath(values, name) || '';

  return (
    <TextField
      type={type}
      variant={variant}
      dir="ltr"
      className={classes.root}
      label={label}
      name={name}
      id={name}
      value={value}
      multiline
      minRows={6}
      onChange={handleChange}
      helperText={errors[name] && errors[name]}
      disabled={disabled}
      FormHelperTextProps={{
        classes: {
          root: classes && classes.helperTextRoot,
        },
      }}
    />
  );
};
