import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { TextFieldSelectUI } from '../../componentsUI/TextFieldSelect';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: '100%',
  },
}));

export const SharePermissionsDropdown = ({ form, label, options = [], update = () => {} }) => {
  const { t } = useTranslation();
  const [state, setState] = useState(form && form.values);
  const classes = useStyles();
  const selectLabel = label || t('allow.permission.to');

  if (!options.length) {
    options.push({ value: 'view', label: 'view' });
    options.push({ value: 'contribute', label: 'contribute' });
  }

  useEffect(() => {
    const values = form && form.values;
    if (values && values !== state) {
      setState(values);
      update(values);
    }
  }, [form]);

  return (
    <TextFieldSelectUI
      name="permission"
      label={selectLabel}
      props={form}
      options={options}
      classes={classes}
    />
  );
};
