import React, { useState } from 'react';
import i18n from 'i18next';

import { Button, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useMutation, useQuery } from 'react-apollo';
import { useSelector } from 'react-redux';
import { parseGraphqlErrors } from '../../utils/FormikUtils';
import { SharePermissionsDropdown } from './SharePermissionsDropdown';
import { isEmpty } from '../../utils/ObjectUtils';
import Loading from '../Common/Loading';
import { Form } from '../Common/styled/Form';

import { GET_MEDICAL_CASE_FOR_EDITING, GET_TEAM_MEMBERS_AND_GROUPS, FIND_USER_PROFILE_BY_UUID } from '../../graphql/queries';
import { ADD_MEDICAL_CASE_COLLABORATION, ADD_MEDICAL_CASE_GROUP_COLLABORATION } from '../../graphql/mutations';
import { AlertUI } from '../../componentsUI/Alert';
import { FormFieldErrorUI } from '../../componentsUI/FormError';

const useStyles = makeStyles((theme) => ({
  label: {
    color: alpha(theme.palette.text.primary, 0.54),
  },
  button: {
    minWidth: 100,
    margin: 6,
    fontWeight: 600,
  },
  helperTextRoot: {
    color: theme.palette.error.main,
  },
}));

export const NewCaseShare = ({ medicalCase, refetch, onClose }) => {
  const { t } = useTranslation();
  const language = i18n && i18n.language;
  const classes = useStyles();
  const hospital = useSelector((state) => state.hospital);
  const [serverErrors, setServerErrors] = useState(false);
  const [memberValue, setMemberValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [isGroupUuid, setIsGroupUuid] = useState(false);
  const { loading, error, data: teamMembers } = useQuery(GET_TEAM_MEMBERS_AND_GROUPS, {
    variables: { uuid: hospital.uuid },
  });
  const { data: hospitalOwner } = useQuery(FIND_USER_PROFILE_BY_UUID, {
    variables: { uuid: hospital.owner },
  });

  const [addCollaboration] = useMutation(ADD_MEDICAL_CASE_COLLABORATION, {
    onCompleted: () => {
      // const newCollaboration = data.collaborations.slice(-1)[0]; // TODO: UPDATE MESSAGE WHEN BACKEND FIXED
      const successMessage = t('collaboration.successfully.added');
      toast(successMessage, { className: 'toast-success' });
    },
    onError: () => {
      toast(
        t('error.adding.collaboration'),
        { className: 'toast-error' },
      );
    },
    refetchQueries: [{ query: GET_MEDICAL_CASE_FOR_EDITING, variables: { uuid: medicalCase.uuid } }],
    awaitRefetchQueries: true,
  });

  const [addGroupCollaboration] = useMutation(ADD_MEDICAL_CASE_GROUP_COLLABORATION, {
    onCompleted: () => {
      // const newCollaboration = data.collaborations.slice(-1)[0]; // TODO: UPDATE MESSAGE WHEN BACKEND FIXED
      const successMessage = t('group.collaboration.successfully.added');
      toast(successMessage, { className: 'toast-success' });
    },
    onError: () => {
      toast(
        t('error.adding.collaboration'),
        { className: 'toast-error' },
      );
    },
    refetchQueries: [{ query: GET_MEDICAL_CASE_FOR_EDITING, variables: { uuid: medicalCase.uuid } }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = async (values, { setErrors, resetForm }) => {
    setServerErrors(false);
    setMemberValue('');
    setInputValue('');
    try {
      if (isGroupUuid) {
        const addNewGroupCollaborationInput = {
          medicalCaseUuid: medicalCase.uuid,
          permission: values.permission.toUpperCase(),
          userGroupUuid: values.member,
          language,
        };
        await addGroupCollaboration({ variables: { input: addNewGroupCollaborationInput } });
        refetch();
      } else {
        const addNewCollaborationInput = {
          medicalCaseUuid: medicalCase.uuid,
          permission: values.permission.toUpperCase(),
          userUuid: values.member,
          language,
        };
        await addCollaboration({ variables: { input: addNewCollaborationInput } });
        refetch();
      }
      resetForm();
    } catch (errors) {
      resetForm();
      const formikErrors = parseGraphqlErrors(errors.graphQLErrors, t);
      if (
        Object.keys(formikErrors).length === 0
        && formikErrors.constructor === Object
      ) {
        setServerErrors(true);
      } else {
        setErrors(formikErrors);
      }
    }
  };

  const validationSchema = Yup.object({
    member: Yup.string().required(t('must.select.member')),
    permission: Yup.mixed().oneOf(['view', 'contribute']),
  });

  const getSuggestionValue = (member) => {
    if (isEmpty(member)) return '';
    const isGroup = 'groupMembers' in member;
    return isGroup ? `${member.name}` : `${member.user.name} ${member.user.surname}`;
  };

  const manager = hospital && hospitalOwner && {
    role: 'MANAGER',
    user: hospitalOwner.userByUuid,
    uuid: hospital.uuid,
    __typename: 'HospitalOwner',
  };

  const rawUsers = teamMembers && teamMembers.hospital && [manager, ...teamMembers.hospital.teamMembers];
  const collaborationUuidArray = medicalCase.collaborations.map((c) => c.user && c.user.uuid);
  const groupCollaborationUuidArray = medicalCase.groupCollaborations.map((c) => c.group && c.group.uuid);
  const rawMembers = rawUsers
    ? rawUsers.filter((member) => !collaborationUuidArray.includes(member && member.user && member.user.uuid))
    : [];
  const groups = teamMembers.hospital
    ? teamMembers.hospital.userGroups.filter((group) => !groupCollaborationUuidArray.includes(group.uuid))
    : [];
  const members = rawMembers && rawMembers.filter((member) => (
    // eslint-disable-next-line no-underscore-dangle
    manager && member.user && manager.user && member.__typename !== 'HospitalOwner'));
  const availableMembers = [...members, ...groups];

  const setFormValues = (event, input, form) => {
    event.preventDefault();

    if (isEmpty(input)) {
      setMemberValue('');
      setInputValue('');
      return;
    }

    const isGroup = 'groupMembers' in input;
    const fieldValue = isGroup ? input.uuid : input.user.uuid;
    setMemberValue(input);
    setInputValue(fieldValue);
    form.setFieldValue('member', fieldValue);
    setIsGroupUuid(isGroup);
  };

  if (error) return <AlertUI severity="error" title="Error">{t('server.error')}</AlertUI>;
  if (loading && isEmpty(teamMembers)) return <Loading />;

  const shareOptions = [
    { value: 'view', label: 'view' },
    { value: 'contribute', label: 'contribute' },
  ];

  return (
    <Formik
      initialValues={{ member: '', permission: 'view' }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Form autoComplete="off">
          {serverErrors && <AlertUI severity="error" title="Error">{t('server.error')}</AlertUI>}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Autocomplete
                id="member"
                value={memberValue}
                onChange={(event, input) => setFormValues(event, input, props)}
                inputValue={inputValue}
                onInputChange={(event, input) => setInputValue(input)}
                options={availableMembers}
                clearOnBlur
                getOptionLabel={(option) => getSuggestionValue(option)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('share.with')}
                  />
                )}
              />
              <ErrorMessage name="member" component={FormFieldErrorUI} />
            </Grid>
            <Grid item xs={12} md={6}>
              <SharePermissionsDropdown form={props} options={shareOptions} />
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end">
              <Button
                variant="outlined"
                color="primary"
                size="large"
                className={classes.button}
                disabled={props.isSubmitting}
                onClick={onClose}
              >
                {t('go.back')}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                disabled={props.isSubmitting}
              >
                {t('add')}
              </Button>
            </Grid>
          </Grid>

          {props.isSubmitting && <Grid item xs={12}><Loading /></Grid>}

          { /* TODO: implement make public in hospital functionality when implemented in backend
          <Checkbox>
          <label htmlFor="share__checkbox">
          <input type="checkbox" id="share__checkbox" />
          {t('case.make.public')}
          </label>
          </Checkbox>
        */ }
        </Form>
      )}
    </Formik>
  );
};
