export const patientGenderList = [
  { id: 0, value: '', label: 'select.a.gender' },
  { id: 1, value: 'MALE', label: 'male' },
  { id: 2, value: 'FEMALE', label: 'female' },
  { id: 3, value: 'NON-BINARY', label: 'non.binary' },
];

export const getGenderLabel = (value) => {
  if (!value) return 'n/a';
  const selected = patientGenderList.find((gender) => gender.value === value);
  return (selected && selected.label) || value;
};
