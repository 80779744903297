import React from 'react';

import { Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4px 12px',
    '@media (max-width:599px)': {
      padding: '4px 6px',
    },
  },
  user: {
    display: 'flex',
    flexDirection: 'row',
  },
  card: {
    verticalAlign: 'middle',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginLeft: 16,
    '@media (max-width:599px)': {
      marginLeft: 8,
    },
  },
  options: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  permission: {
    marginRight: 8,
    width: 120,
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: 40,
  },
}));

export const ShareDetailsContainer = ({ children }) => {
  const styles = useStyles();

  return (
    <Grid container className={styles.container}>
      {children}
    </Grid>
  );
};

export const ShareDetailsUserItem = ({ children }) => {
  const styles = useStyles();

  return (
    <Grid item xs={12} sm={6} className={styles.user}>
      {children}
    </Grid>
  );
};

export const ShareUserInfoCard = ({ children }) => {
  const styles = useStyles();

  return (
    <Grid item xs={12} sm={6} className={styles.card}>
      {children}
    </Grid>
  );
};

export const ShareDetailsOptionsWrapper = ({ children }) => {
  const styles = useStyles();

  return (
    <Grid item xs={12} sm={6} className={styles.options}>
      {children}
    </Grid>
  );
};

export const ShareDetailsOptionsItem = ({ children }) => {
  const styles = useStyles();

  return (
    <Box className={styles.permission}>
      {children}
    </Box>
  );
};

export const ShareDetailsButtonWrapper = ({ children }) => {
  const styles = useStyles();

  return (
    <Box className={styles.button}>
      {children}
    </Box>
  );
};
