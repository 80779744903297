import React from 'react';
import { useTranslation } from 'react-i18next';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { GetValue } from '../utils/functions';

const OrderIcon = ({ className, direction }) => (
  (direction === 'ASC') ? <ArrowDropDownIcon className={className} /> : <ArrowDropUpIcon className={className} />
);

export const HeaderFields = ({ classes, fieldNames, posLeft, order, handleOrder }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.tableHeaderScroll}>
      <div className={classes.tableHeader} style={{ left: posLeft }}>
        <div style={{ width: 40 }} />
        {fieldNames.map((item, index) => (item.width > 0
          ? (
            <div
              key={index.toString()}
              className={classes.tableField}
              style={{
                width: item.width,
                justifyContent: item.align ? item.align : 'flex-start',
              }}
              onClick={() => handleOrder(item)}
              aria-hidden="true"
            >
              <span>{item.width > 25 && t(item.label)}</span>
              {(order && item.field === order.field) && <OrderIcon direction={order.direction} className={classes.orderIcon} />}
            </div>
          )
          : undefined))}
        <div style={{ width: 40 }} />
      </div>
    </div>
  );
};

export const ItemFieldsResponsive = ({ classes, item, GetTitle, GetSubtitle }) => (
  <div className={classes.headerCollapse}>
    <span className={classes.title}>{!!GetTitle && GetTitle(item)}</span>
    <span className={classes.subtitle}>{!!GetSubtitle && GetSubtitle(item)}</span>
  </div>
);

export const ItemFields = ({ classes, fieldNames, item }) => (
  fieldNames.map((col, i) => {
    if (col.width > 0) {
      const Item = GetValue(item, col);
      const wrapper = typeof Item === 'object' ? 'icon' : 'ellipsis';

      return (
        <span
          key={i.toString()}
          className={classes.itemField}
          style={{
            width: col.width,
            justifyContent: col.align ? col.align : 'flex-start',
          }}
        >
          <div className={wrapper}>
            {Item}
          </div>
        </span>
      );
    }
    return null;
  })
);
