import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';

import { Badge } from '@material-ui/core';
import {
  AssignmentTwoTone,
  FolderSharedTwoTone,
  InsertDriveFileTwoTone,
  InsertPhotoTwoTone,
  Person,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { SectionBar } from '../../componentsUI/SectionBar';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { ProtocolAddDialog } from '../Protocols/ProtocolAddDialog';
import { UploadFileDialog } from '../File/UploadFileDialog';
import { ContainerUI } from '../Common/styled/ContainerUI';
import { DashboardCasesView } from './DashboardCasesView';
import { DashboardStudiesView } from './DashboardStudiesView';

export const useStyles = makeStyles(() => ({
  badge: {
    '& .MuiBadge-badge': {
      fontWeight: 900,
      color: '#666D',
      backgroundColor: '#AAA6',
    },
  },
}));

const MEDICAL_CASES = 'medical-cases';
const DICOM_STUDIES = 'dicom-studies';

export const DashboardView = () => {
  const classes = useStyles();
  const [showProtocolModal, setShowProtocolModal] = useState(false);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const hospital = useSelector((state) => state.hospital);

  const dashboard = (() => {
    const type = (() => {
      if (hospital.menuOptionMedicalCases) return MEDICAL_CASES;
      if (hospital.menuOptionStudies) return DICOM_STUDIES;
      return MEDICAL_CASES;
    })();

    return {
      type,
      medicalCases: type === MEDICAL_CASES,
      dicomStudies: type === DICOM_STUDIES,
    };
  })();

  const handleGotoNewCase = () => {
    navigate('/cases/new');
  };

  const handleGotoUploadStudy = () => {
    navigate(`/study/upload/${hospital.uuid}?uth=1`);
  };

  const handleOpenProtocolModal = () => {
    setShowProtocolModal(true);
  };

  const handleCloseProtocolModal = () => {
    setShowProtocolModal(false);
  };

  const handleOpenUploadFileModal = () => {
    setShowUploadFileModal(true);
  };

  const handleCloseUploadFile = () => {
    setShowUploadFileModal(false);
  };

  const handleUploadFileCompleted = () => {
    setShowUploadFileModal(false);
    navigate('/files');
  };

  const UploadAddPatientIcon = () => (
    <Badge badgeContent="+" className={classes.badge} overlap="circular">
      <Person />
    </Badge>
  );

  const UploadAddMedicaCaseIcon = () => (
    <Badge badgeContent="+" className={classes.badge} overlap="circular">
      <FolderSharedTwoTone />
    </Badge>
  );

  const UploadAddProtocolIcon = () => (
    <Badge badgeContent="+" className={classes.badge} overlap="circular">
      <AssignmentTwoTone />
    </Badge>
  );

  const UploadStudyIcon = () => (
    <Badge badgeContent="+" className={classes.badge} overlap="circular">
      <InsertPhotoTwoTone />
    </Badge>
  );

  const UploadFileIcon = () => (
    <Badge badgeContent="+" className={classes.badge} overlap="circular">
      <InsertDriveFileTwoTone />
    </Badge>
  );

  const buttons = [
    { name: 'new.patient', icon: UploadAddPatientIcon, handleClick: () => { navigate('/patients/new'); }, hidden: !hospital.menuOptionPatients },
    { name: 'new.case', icon: UploadAddMedicaCaseIcon, handleClick: handleGotoNewCase, hidden: !hospital.menuOptionMedicalCases },
    { name: 'new.protocol', icon: UploadAddProtocolIcon, handleClick: handleOpenProtocolModal, hidden: !hospital.menuOptionProtocols },
    { name: 'upload.study', icon: UploadStudyIcon, handleClick: handleGotoUploadStudy, hidden: !hospital.menuOptionStudies },
    { name: 'upload.file', icon: UploadFileIcon, handleClick: handleOpenUploadFileModal, hidden: !hospital.menuOptionFiles },
  ];

  const sectionTitle = (() => {
    switch (dashboard.type) {
      case DICOM_STUDIES:
        return 'studies';
      case MEDICAL_CASES:
      default:
        return 'recent.cases';
    }
  })();

  return (
    <ContainerUI>
      <Navbar>
        <SectionBar title={sectionTitle} items={buttons} />
      </Navbar>
      <ProtocolAddDialog
        open={showProtocolModal}
        onClose={handleCloseProtocolModal}
      />
      <UploadFileDialog
        open={showUploadFileModal}
        onClose={handleCloseUploadFile}
        handleCompleted={handleUploadFileCompleted}
        uuid={hospital.uuid}
        type="hospitalFile"
      />
      {dashboard.medicalCases && <DashboardCasesView />}
      {dashboard.dicomStudies && <DashboardStudiesView />}
    </ContainerUI>
  );
};
