import React from 'react';
import { useTranslation } from 'react-i18next';

import Paper from '@material-ui/core/Paper';
import { Box, Grid, Typography } from '@material-ui/core';

import InfoAccordion from '../../componentsUI/InfoAccordion';
import useStyles from './styled/CaseMakeStyles';
import { ShareIcons } from './styled/ShareStyles';
import { isEmptyOrAllNull } from '../../utils/ObjectUtils';
import { AvatarTooltipUI, AvatarGroupTooltipUI } from '../../componentsUI/Avatar';

export const MedicalCaseShareInfo = ({ medicalCase }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const collaborations = medicalCase.collaborations.filter((collaboration) => collaboration.user).map((collaboration) => collaboration.user);
  const sharedWith = [...collaborations, ...medicalCase.groupCollaborations];

  const createShareIcons = () => sharedWith.map((collaborator, index) => {
    // eslint-disable-next-line no-underscore-dangle
    if (collaborator.__typename === 'MedicalCaseGroupCollaboration') {
      return (
        <AvatarGroupTooltipUI key={index.toString()} size={42} group={collaborator.group} />
      );
    }
    return (
      <AvatarTooltipUI key={index.toString()} size={42} user={collaborator} />
    );
  });

  const InfoMedicalCaseSharedWith = () => (
    <ShareIcons style={{ margin: '1.125em .75em 0.5em' }}>
      {isEmptyOrAllNull(sharedWith)
        ? <Typography variant="body2">{t('case.not.shared')}</Typography>
        : createShareIcons()}
    </ShareIcons>
  );

  return (
    <Grid item xs={12} md={4} lg={5}>
      <Paper elevation={2}>
        <Box className={classes.box}>
          <Grid container spacing={1}>
            <Grid item className={classes.accordionInfo}>
              <InfoAccordion title={`${t('shared.with')}...`} Item={InfoMedicalCaseSharedWith} expanded />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};

export default MedicalCaseShareInfo;
