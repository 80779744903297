import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GetFormat } from '../utils/functions';
import { objectPathValue } from '../utils/ObjectUtils';

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: '0 20px',
    paddingBottom: 12,
    fontSize: '0.8em',
  },
  label: {
    color: 'rgba(0,0,0,.4)',
  },
  value: {
    marginLeft: 10,
  },
});

const SplitGroup = (array, size) => [...array.map((elem, index) => (index % size ? null : array.slice(index, index + size)))];

const ItemCollapse = ({ item, fieldNames }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const groups = SplitGroup(fieldNames.filter((filter) => (!filter.headerOnly)), 5);

  return (
    <div className={classes.root}>
      <Grid container>
        {groups.map((group, index1) => (
          <Grid key={index1.toString()} item xs={12} sm={6}>
            {group && group.filter((field) => field.headerOnly !== true).map((field, index2) => (
              <div key={index2.toString()}>
                <span className={classes.label}>{`${t(field.label)}:`}</span>
                <span className={classes.value}>{item && GetFormat(objectPathValue(item, field.id), field.format)}</span>
              </div>
            ))}
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ItemCollapse;
