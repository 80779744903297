import React from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';

import { Paper, Typography } from '@material-ui/core';

import { isEmpty, sortGraphQueryList } from '../../../utils/ObjectUtils';
import Loading from '../../Common/Loading';

import { GET_PATIENT_VIRTUAL_VISITS } from '../../../queries/VirtualVisits/VirtualVisits';
import TableCollapse from '../../../componentsUI/TableCollapse';
import { DATE_FORMAT, GetFormat } from '../../../utils/functions';
import { networkErrorParse } from '../../../utils/ErrorGraphQLUtils';
import { AlertWrapperUI, GraphQLAlertUI } from '../../../componentsUI/Alert';
import { useDashboardStyles } from '../Styles/DashboardStyles';

const fieldNames = [
  { label: 'medical.case', id: 'title', field: 'TITLE', direction: 'ASC', width: 370 },
  { label: 'case.created', id: 'createdAt', field: 'CREATED_AT', direction: 'DESC', width: 120, format: DATE_FORMAT },
];

export const VirtualVisitsView = ({ selectedVisit, selectVisit }) => {
  const { t } = useTranslation();
  const classes = useDashboardStyles();

  const orderByField = 'CREATED_AT';
  const orderByDirection = 'DESC';

  const user = useSelector((state) => state.userInterface.user);

  const { loading, error, data, refetch } = useQuery(
    GET_PATIENT_VIRTUAL_VISITS, {
      variables: {
        patientUuid: user.patientUuid,
        first: 20,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const errorMessage = networkErrorParse(error);

  const virtualVisits = data && data.virtualVisits && data.virtualVisits.edges
    && data.virtualVisits.edges.length > 0 && sortGraphQueryList(
    data.virtualVisits.edges,
    fieldNames,
    orderByField,
    orderByDirection,
  ).slice(0, 5).map((item) => item.node);

  const handleViewVisits = (evt) => {
    evt.stopPropagation();

    navigate('/virtual-visits');
  };

  const handleGotoVisit = (evt, index) => {
    evt.stopPropagation();

    navigate(`/virtual-visit/${virtualVisits[index].uuid}`);
  };

  const defined = (item) => (item === 0 || item !== null);

  const selected = (virtualVisits && selectedVisit)
    ? virtualVisits.map((item, key) => (item.uuid === selectedVisit.uuid ? key : null)).filter(defined)
    : [];

  const handleSelectVirtualVisit = (sel) => {
    if (!sel) return;
    if (!sel.length) {
      selectVisit(null);
      return;
    }

    selectVisit(selected.length && sel[0] === selected[0] ? null : virtualVisits[sel[0]]);
  };

  const getVisitTitle = (item) => {
    const doctor = item.doctor.fullname;
    const medicalCase = item.medicalCase && item.medicalCase.title;

    return `${t('doctor')}: ${doctor} | ${medicalCase}`;
  };

  const getVisitSubTitle = (item) => {
    const answered = item.answered;

    if (answered) return t('reply.completed');
    return `${t('appointment')}: ${GetFormat(item.appointment, DATE_FORMAT)}`;
  };

  if (errorMessage) {
    return (
      <AlertWrapperUI>
        <GraphQLAlertUI severity="warning" title="Error" refetch={refetch}>
          {t(errorMessage)}
        </GraphQLAlertUI>
      </AlertWrapperUI>
    );
  }

  return (
    <>
      <Paper elevation={2} className={classes.title} onClick={handleViewVisits}>
        <Typography>
          {t('virtual.visits')}
        </Typography>
      </Paper>
      { loading && isEmpty(data) && <Loading /> }
      { virtualVisits
      && (
        <TableCollapse
          responsive={false}
          fieldNames={fieldNames}
          items={virtualVisits}
          GetTitle={getVisitTitle}
          GetSubtitle={getVisitSubTitle}
          selected={selected}
          setSelected={handleSelectVirtualVisit}
          handleGoto={handleGotoVisit}
        />
      )}
    </>
  );
};
