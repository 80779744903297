import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, makeStyles, Typography } from '@material-ui/core';

import { navigate } from 'gatsby';
import InfoAccordion from '../../../componentsUI/InfoAccordion';
import { getFormattedDate } from '../../../utils/dateTimeUtils';
import { useApplicationInterface } from '../../../utils/ApplicationInterfaceUtils';
import { GetFormat } from '../../../utils/functions';
import ArchivesTableCollapse from '../../../componentsUI/ArchivesTableCollapse';

const { appDoctor, appPatient } = useApplicationInterface();

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '0 2em 1em',
  },
  image: {
    maxHeight: 32,
  },
  title: {
    'div[class*="itemSelected"] &': {
      fontWeight: 700,
    },
    '& .MuiSvgIcon-root': {
      margin: '0 6px -3px 6px',
      color: theme.palette.primary.light,
      fontSize: '2rem',
    },
  },
  subtitle: {
    margin: '0 .375em',
    color: theme.palette.grey.A200,
    '& > p': {
      fontSize: '0.875em',
      marginRight: 8,
    },
    '& $patient': {
      fontSize: '1em',
    },
  },
  patient: {
    fontWeight: 700,
    color: theme.palette.primary.light,
    'div[class*="itemSelected"] &': {
      color: theme.palette.primary.main,
    },
  },
  doctor: {
    fontSize: '1.25rem',
    fontWeight: 600,
    color: theme.palette.grey.A200,
  },
  uuid: {
    'div[class*="itemSelected"] &': {
      color: theme.palette.grey.A700,
    },
  },
}));

export const CaseVirtualVisits = ({ virtualVisits }) => {
  const [selected, setSelected] = useState([]);
  const user = useSelector((state) => state.userInterface.user);
  const { t } = useTranslation();
  const styles = useStyles();

  const getTitle = (item) => (
    <Box className={styles.title}>
      {item && getFormattedDate({ date: item.appointment, format: 'weekdayComma' })}
      {GetFormat(item.answered, 'VIRTUALVISITREPLY')}
      {appDoctor && user && item.doctor.uuid !== user.uuid && (
        <Typography className={styles.doctor} display="inline">
          {`${item.doctor.name} ${item.doctor.surname}`}
        </Typography>
      )}
    </Box>
  );

  const getSubTitle = (item) => (
    <Box className={styles.subtitle}>
      {appDoctor && (
        <Typography className={styles.patient} display="inline">
          {`${item.patient.name} ${item.patient.surname}`}
        </Typography>
      )}
      {appPatient && (
        <Typography className={styles.patient} display="inline">
          {`${item.doctor.name} ${item.doctor.surname}`}
        </Typography>
      )}
      <Typography className={styles.uuid} display="inline">
        {item.uuid}
      </Typography>
    </Box>
  );

  const handleGoto = (evt, index) => {
    evt.stopPropagation();
    const virtualVisit = virtualVisits[index];

    navigate(`/virtual-visit/${virtualVisit.uuid}`);
  };

  const emptyList = !virtualVisits || !virtualVisits.length;

  const VirtualVisitsList = () => {
    if (emptyList) return <Box style={{ margin: '.5em 1em' }}>{t('no.virtual.visits')}</Box>;

    return (
      <ArchivesTableCollapse
        responsive={false}
        fieldNames={null}
        items={virtualVisits}
        GetTitle={getTitle}
        GetSubtitle={getSubTitle}
        selected={selected}
        setSelected={setSelected}
        handleGoto={handleGoto}
      />
    );
  };

  return <InfoAccordion title={t('virtual.visits')} Item={VirtualVisitsList} />;
};

export default CaseVirtualVisits;
