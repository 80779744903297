import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const cardWrapperUIBoxStyles = makeStyles(() => ({
  root: {
    height: 'calc(100vh - 110px)',
    padding: '0 2em',
    position: 'relative',
    '@media (min-width:960px)': {
      height: 'calc(100vh - 155px)',
    },
    '@media (max-width:1099px)': {
      padding: 0,
    },
  },
}));

const cardWrapperScrollUIBoxStyles = makeStyles((theme) => ({
  root: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: 'calc(100vh - 400px)',
    minHeight: 250,
    '&::-webkit-scrollbar': {
      width: 3,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.light,
    },
  },
}));

export const CardWrapperUI = ({ children, className }) => {
  const styles = cardWrapperUIBoxStyles();

  return (
    <Box className={`MuiCard-wrapper ${styles.root} ${className}`}>
      {children}
    </Box>
  );
};

export const CardWrapperScrollUI = ({ children }) => {
  const styles = cardWrapperScrollUIBoxStyles();

  return (
    <Box className={`MuiCard-wrapper-scroll ${styles.root}`}>
      {children}
    </Box>
  );
};
