import { makeStyles } from '@material-ui/core';

const userInfoStyles = () => makeStyles((theme) => ({
  adminInfo: {
    marginTop: 'auto',
    padding: '.25em .5em 0',
    '& > .MuiGrid-container': {
      justifyContent: 'flex-end',
      alignItems: 'baseline',
    },
  },
  avatar: {
    width: '1.875em',
    height: '1.875em',
    maxWidth: '100%',
    maxHeight: '100%',
    marginTop: '1em',
    border: '1px #ddd solid',
    cursor: 'pointer',
    '@media (max-width:600px)': {
      marginTop: 0,
    },
  },
  initials: {
    width: '2.5em',
    height: '2.5em',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    fontSize: '.9375em',
    cursor: 'pointer',
  },
  popover: {
    fontSize: '1.25rem',
    padding: '0.75em',
  },
}))();

export default userInfoStyles;
