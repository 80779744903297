import { makeStyles } from '@material-ui/core';

const useStyles = () => makeStyles((theme) => ({
  subheader: {
    borderBottom: '1px solid #0002',
  },
  out: {
    marginTop: '.25em',
    marginBottom: '.25em',
  },
  wrapper: {
    width: 'calc(100% - 40px)',
    maxWidth: 600,
    margin: '.5em auto',
    padding: '1em 1.25em',
    '@media (max-width:600px)': {
      padding: '.5em 1em',
    },
  },
  commentWrapper: {
    width: 'calc(100% - 32px)',
    maxWidth: 600,
    margin: '.5em 0',
    padding: '1em 1.25em',
    '@media (max-width:600px)': {
      padding: '.5em 1em',
    },
  },
  case: {
    position: 'relative',
    width: 'calc(100% - 2em)',
    flexGrow: 1,
    padding: '1.25em 1em',
    '& .MuiGrid-item': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  userInfo: {
    position: 'absolute',
    bottom: 12,
    right: 6,
  },
  box: {
    width: '100%',
    flexGrow: 1,
    padding: '1.25em 0',
  },
  accordion: {
    margin: '-.5em 0',
    width: '100%',
    '& .MuiAccordionSummary-root': {
      color: theme.palette.common.black,
      background: theme.palette.primary.light,
    },
  },
  patientInfo: {
    fontSize: '.9em',
    marginTop: '-.25em',
  },
  container: {
    maxWidth: '100%',
    padding: '.75em 2.5em',
    '@media (max-width:600px)': {
      padding: '.75em 1.5em',
    },
  },
  caseStatus: {
    marginBottom: '-.5em',
    width: '100%',
    textAlign: 'right',
    '& .MuiChip-root': {
      fontSize: '1em',
    },
    '& .MuiChip-colorPrimary': {
      color: theme.palette.common.white,
    },
  },
  indent: {
    padding: '0 1em',
  },
  caseTitle: {
    marginBottom: '.5em',
    fontSize: '1.75em',
    color: theme.palette.primary.dark,
  },
  subtype: {
    marginBottom: '.125em',
    fontSize: '.9375em',
    '& > .value': {
      fontSize: '1.125em',
      fontWeight: 700,
    },
  },
  description: {
    margin: '.25em 0 .375em -.5em',
    padding: '.375em .75em',
    width: 'calc(100% - 1em)',
    border: '1px solid #e7e7e7',
    borderRadius: 6,
    fontSize: '.9375em',
    textAlign: 'justify',
  },
  accordionMain: {
    margin: '0',
    width: '100%',
    '& .MuiAccordionSummary-root': {
      margin: '0 -.5em',
      color: theme.palette.common.white,
      background: theme.palette.primary.dark,
    },
  },
  accordionInfo: {
    margin: '-.5em .5em',
    width: 'calc(100% - 1em)',
    '& .MuiAccordionSummary-root': {
      margin: '0 -.5em',
      background: theme.palette.primary.main,
    },
  },
  accordionSecondary: {
    margin: '0',
    width: '100%',
    '& .MuiAccordionSummary-root': {
      margin: '0 -.5em',
    },
  },
  accordionStudies: {
    margin: '0',
    width: '100%',
    '& .MuiAccordionSummary-root': {
      margin: '0 -.5em',
      color: theme.palette.common.white,
      background: theme.palette.primary.main,
    },
    '& .MuiCollapse-entered': {
      margin: '.5em 0 0',
    },
  },
  adminInfo: {
    marginTop: 'auto',
    padding: '.25em .5em 0',
    '& > .MuiGrid-container': {
      justifyContent: 'flex-end',
      alignItems: 'baseline',
    },
  },
  list: {
    paddingInlineStart: 30,
    paddingInlineEnd: 8,
    fontSize: '.9em',
    color: theme.palette.grey.A700,
  },
  noItems: {
    marginLeft: '.375em',
    padding: 8,
    color: theme.palette.grey.A200,
  },
}))();

export default useStyles;
